import React, { useState } from 'react';
import {
  Col, DatePicker, FormInput, FormSelect,
} from "shards-react";
import { useEffect } from "react";
import CustomCard from "../common/CustomCard";
import InputBox from "../common/InputBox";
import InfoTooltip from '../common/InfoTooltip';
import { setDateForTable, setInputDate } from '../../utils/date';
import { numberToMonetary } from '../../utils/general';
import { SUBSCRIBER_ROLES } from '../../utils/constants';


function EmploymentInfo({ userId, subStatus,
  workerFlag,
  clientAdminFlag,
  clientUserFlag,
  clientHRFlag,
  clientBillingFlag,
  countryList,
  regionList,
  get_region_list,
  clientId,
  IsClientEditable,
  hireDate,
  clientContactId,
  endDate,
  clientList,
  contactList,
  noticePeriod,


  jobTitle,
  yearsOfService,
  employmentType,
  noticePeriodUnit,
  vacationPay,

  empCountryId,
  empRegionId,
  onDateChange,
  department,
  payrollId,

  clientBillingContactName,
  onChange,

  queryParamsRole,
  subStatusList,
  weeklyHoursPerPay,
  biWeeklyHoursPerPay,
  semiMonthlyHoursPerPay,
  monthlyHoursPerPay,
  onMonetaryAmountChanged,
  empEffectiveDate,
  hrConsultantId,
  salesConsultantId,
  hoursPerWeek,
  daysPerWeek,
  hrConsultantList,
  salesConsultantList,
  setAlert,
  benefitsInfos,
  pensionInfos,
  EAPInfos,
  HSAInfos,
  employerPayrollTaxes,
  userRole,
}) {

  const [isHRSaleConsultMandatory, setisHRSaleConsultMandatory] = useState(!(userRole.selectedrole === SUBSCRIBER_ROLES.SYSTEM_ADMIN
    || userRole.selectedrole === SUBSCRIBER_ROLES.SUBSCRIBER_USER || userRole.selectedrole === SUBSCRIBER_ROLES.SUBSCRIBER_HR
    || userRole.selectedrole === SUBSCRIBER_ROLES.SUBSCRIBER_ADMIN || userRole.selectedrole === SUBSCRIBER_ROLES.SUBSCRIBER_BILLING
  ));
  useEffect(() => {
    if (empCountryId) {
      get_region_list(empCountryId)
    }
  }, [empCountryId])

  var clientListOptions = clientList.map((option) => {
    return <option key={option.clientName} value={option.clientId}>{option.clientName}</option>;
  });

  var contactListOptions = contactList.map((status) => {
    return <option key={status.name + status.userId} value={status.userId}>{status.name}</option>;
  });

  var regionListOption = regionList.map((option) => {
    return <option key={option.regionId}
      value={option.regionId}>{option.regionName}</option>;
  })
  var countryListOption = countryList.map((option) => {
    return <option key={option.countryId}
      value={option.countryId}>{option.countryName}</option>;
  });
  var substatusListOptions = subStatusList && subStatusList.map(
    (substatus) => {
      return <option key={substatus} value={substatus}>{substatus}</option>;
    }
  );
  var clientBlillingListOptions = contactList.map((status) => {
    return <option key={status.name + status.userId} value={status.name}>{status.name}</option>;
  });
  var hrConsultantListOption = hrConsultantList.map((option) => {
    return <option key={option.userId} value={option.userId}>{option.userName}</option>;
  });
  var salesConsultantListOption = salesConsultantList.map((option) => {
    return <option key={option.userName + option.userId} value={option.userId}>{option.userName}</option>;
  });

  const onHireDateChange = (date)=>{

    let selectedDate = setInputDate(date);
    let selectedClient = clientList.filter(e => Number(e.clientId) === Number(clientId));

    if(selectedClient.length > 0 && Number(selectedDate) < Number(selectedClient[0].startDate)){
      setAlert("Hire Date cannot be less than the Client's start date", "danger");
      return;
    }

    if((benefitsInfos && benefitsInfos.length > 0 && Number(selectedDate) > Number(benefitsInfos[0].benefitsStartDate))
    || (pensionInfos && pensionInfos.length > 0 && (Number(selectedDate) > Number(pensionInfos[0].myMatchStartDate) || Number(selectedDate) > Number(pensionInfos[0].clientMatchStartDate)))
    || (EAPInfos && EAPInfos.length > 0 && Number(selectedDate) > Number(EAPInfos[0].EAPStartDate))
    || (HSAInfos && HSAInfos.length > 0 && Number(selectedDate) > Number(HSAInfos[0].HSAStartDate))){
    
      setAlert("Hire date cannot be changed as the subscribed services start date is less than the entered hire date.", "danger");
      return;
    }

    
    onDateChange("hireDate", date)
  }

  const onEmpEffectiveDate = (date)=>{
    let empHireDate = new Date(hireDate).setHours(0, 0, 0, 0);
    let effectiveDate = new Date(date).setHours(0, 0, 0, 0);
    let selectedClient = clientList.filter(e => Number(e.clientId) === Number(clientId));
    let selectedClientDate = (selectedClient && selectedClient.length > 0) ? new Date(setDateForTable(selectedClient[0].startDate)).setHours(0,0,0,0) : new Date().setHours(0,0,0,0)

    if(selectedClient.length > 0 && effectiveDate < selectedClientDate){
      setAlert("Effective Date cannot be less than the Client's start date","danger");
      return;
    }
    if(effectiveDate < empHireDate){
      setAlert("Effective Date must not be less than Hire Date.","danger");
      return;
    }

   

    onDateChange("empEffectiveDate", date)
  }
  return (

    <CustomCard id="employment" title="Employment Information" icon={true} noform
      iconClass="fas fa-users iconStyle" className={"userprofile mb-5 w-100 d-flex personalParent"}>

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="clientId">Client</label>
        {(workerFlag || clientAdminFlag || clientUserFlag || clientHRFlag || clientBillingFlag)&& <span aria-hidden="true" className="required"> *</span>}
        <FormSelect required={(workerFlag || clientAdminFlag || clientUserFlag || clientHRFlag || clientBillingFlag)}  className='d-block' disabled={userId !== 0 && (IsClientEditable > 0)} name='clientId'
          value={clientId} onChange={onChange}>
          <option value=''>Select Client</option>
          {clientListOptions}
        </FormSelect>
      </Col>

  
      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="hireDate">Hire Date</label>
        <span aria-hidden="true" className="required"> *</span>
        <DatePicker
          id='hireDate'
          required={true}
          autoComplete='off'
          name='hireDate'
          dateFormat='yyyy-MM-dd'
          selected={hireDate}
          onChange={(date) => {
            onHireDateChange(date)
          }

          }
          value={hireDate}
          placeholderText="YYYY-MM-DD"
        />
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="endDate">End Date</label>
        <DatePicker
          id='endDate'
          autoComplete='off'
          name='endDate'
          required={subStatus === "Terminated"}
          dateFormat='yyyy-MM-dd'
          selected={endDate}
          onChange={(date) =>
            onDateChange("endDate", date)
          }
          value={endDate}
          placeholderText="YYYY-MM-DD"
        />
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="yearsOfService">Years of Service</label>
        <FormInput
          id="yearsOfService"
          name="yearsOfService"
          value={yearsOfService}
          onChange={onChange}
          placeholder="Enter Years of Service"
          disabled
        />
      </Col>}

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="jobTitle">Job Title</label>
        <FormInput
          id="jobTitle"
          name="jobTitle"
          value={jobTitle}
          onChange={onChange}
          placeholder="Enter Job Title"
        />
      </Col>

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="department">Department</label>
        <FormInput
          id="department"
          name="department"
          value={department}
          onChange={onChange}
          placeholder="Enter Department"
        />
      </Col>

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="country">Country</label>
        <span aria-hidden="true" className="required"> *</span>
        <FormSelect name='empCountryId'
          required={true}
          value={empCountryId}
          onChange={onChange} className='d-block'>
          <option value=''>Select Country Name</option>
          {countryListOption}
        </FormSelect>
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="state">State/Province of Employment</label>
        <span aria-hidden="true" className="required"> *</span>
        <FormSelect name='empRegionId'
          required={true}
          value={empRegionId}
          onChange={onChange} className='d-block'>
          <option value=''>Select State/Province</option>
          {regionListOption}
        </FormSelect>
      </Col>}

     {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="payrollId">Payroll ID</label>
        <FormInput
          id="payrollId"
          name="payrollId"
          value={payrollId}
          onChange={onChange}
          placeholder="Enter Payroll ID"
        />
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="vacationPay">Vacation Pay %</label>

        <FormSelect name='vacationPay'

          value={vacationPay}
          onChange={onChange} className='d-block'>
          <option value=''>Select Vacation Pay %</option>
          <option value='4'>4</option>
          <option value='5'>5</option>
          <option value='6'>6</option>
        </FormSelect>
      </Col>}

      {workerFlag && <InputBox required={true} placeholder="Enter Notice Period" label="Notice Period" id='noticePeriod' name='noticePeriod'
        value={noticePeriod}
        type="number"
        onChange={onChange} />}

      { workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="noticePeriodUnit">Notice Period Unit</label>
        <FormSelect name='noticePeriodUnit'

          value={noticePeriodUnit}
          onChange={onChange} className='d-block'>
          <option value=''>Select  Notice Period Unit</option>
          <option value='Weeks'>Weeks</option>
          <option value='Months'>Months</option>
        </FormSelect>
      </Col>}

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="subStatus"> Employment Status</label>
        <span aria-hidden="true" className="required"> *</span>
        <FormSelect required={true} id="subStatus" value={subStatus}
          name='subStatus' onChange={onChange}
          className='d-block'>
          <option value=''>Select  Employment Status</option>
          {substatusListOptions}

        </FormSelect>
      </Col>

      { workerFlag &&<><Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="employmentType">Employment Type</label>

        <FormSelect name='employmentType'
          value={employmentType}
          onChange={onChange} className='d-block'>
          <option value=''>Select Employment Type</option>
          <option value='Full Time'>Full Time</option>
          <option value='Part Time'>Part Time</option>
        </FormSelect>
      </Col>
      
     
      <InputBox
        id="employerPayrollTaxes"
        name="employerPayrollTaxes"
        label="Employer Payroll Taxes"
        required={true}
        value={employerPayrollTaxes}
        onChange={(e)=> onMonetaryAmountChanged(e,3)}
        placeholder="0.000"
      /></> }
      
      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="clientContactId">Direct Supervisor</label>
        <span aria-hidden="true" className="required"> *</span>
        <InfoTooltip msg="Client’s First Point of Contact for the Worker" />
        <FormSelect required={true} className='d-block' id="clientContactId"
          placeholder="Direct Supervisor"
          name='clientContactId'
          value={clientContactId}
          onChange={onChange}>
          <option value=''>Select Direct Supervisor</option>
          {contactListOptions}
        </FormSelect>
      </Col>}
      
      {/* {workerFlag && <Col lg="3" className="form-group m-0 p-2"></Col>} */}
      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="hrConsultant">HR Consultant</label>
        { isHRSaleConsultMandatory && <span aria-hidden="true" className="required"> *</span> }
        
        <InfoTooltip msg="List of Subscriber Admin & HR Users" />
        <FormSelect 
          required={isHRSaleConsultMandatory}
          value={hrConsultantId} id="hrConsultantId"
          name='hrConsultantId' onChange={onChange}
          className='d-block'>
          <option value=''>Select HR Consultant
          </option>
          {hrConsultantListOption}
        </FormSelect>

      </Col>

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="salesConsultant">Sales
          Consultant</label>
        { isHRSaleConsultMandatory && <span aria-hidden="true" className="required"> *</span> }
        <InfoTooltip msg="List of Subscriber Users" />
        <FormSelect value={salesConsultantId} id="salesConsultantId"
          name='salesConsultantId' onChange={onChange}
          required={isHRSaleConsultMandatory}
          className='d-block'>
          <option value=''>Select Sales Consultant
          </option>
          {salesConsultantListOption}
        </FormSelect>

      </Col>
      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="clientBillingContactName">Client Billing Contact Name</label>
        <span aria-hidden="true" className="required"> *</span>
        <InfoTooltip msg="List of Client Users" />
        {/*<FormInput
          required={true}
          id="clientBillingContactName"
          name="clientBillingContactName"
          value={clientBillingContactName}
          onChange={onChange}
          placeholder="Enter Client Billing Contact Name"
        />*/}
        <FormSelect required={true}  value={clientBillingContactName} id="clientBillingContactName"
          name='clientBillingContactName' onChange={onChange}
          className='d-block'>
          <option value=''>Select Client Billing Contact Name
          </option>
          {clientBlillingListOptions}
        </FormSelect>
      </Col>}
      {workerFlag && <Col lg="3" className="form-group m-0 p-2"></Col>}
      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="hoursPerWeek">Hours Per Week</label>
        <span aria-hidden="true" className="required"> *</span>
        <FormInput
          required={true}
          id="hoursPerWeek"
          name="hoursPerWeek"
          value={hoursPerWeek}
          onChange={(e)=> onMonetaryAmountChanged(e,2)}
          placeholder="Enter Hours Per Week"
        />
      </Col>}

      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="daysPerWeek">Days Per Week</label>
        <span aria-hidden="true" className="required"> *</span>
        <FormInput
          required={true}
          id="daysPerWeek"
          name="daysPerWeek"
          value={daysPerWeek}
          onChange={(e)=> onMonetaryAmountChanged(e,2)}
          placeholder="Enter Days Per Week"
        />
      </Col>}

      <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="empEffectiveDate">Effective Date</label>
        <span aria-hidden="true" className="required"> *</span>
        <InfoTooltip msg="Effective date is the date when a change takes effect" />
        <DatePicker
          disabled={userId === 0 }
          id='empEffectiveDate'
          autoComplete='off'
          name='empEffectiveDate'
          required={true}
          dateFormat='yyyy-MM-dd'
          selected={empEffectiveDate}
          onChange={(date) =>           
            onEmpEffectiveDate(date)
          }
          value={empEffectiveDate}
          placeholderText="YYYY-MM-DD"
        />
      </Col>
      {workerFlag && <Col lg="3" className="form-group m-0 p-2"></Col>}
      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="weeklyHoursPerPay">Weekly Hours Per Pay</label>
        <FormInput
          id="weeklyHoursPerPay"
          name="weeklyHoursPerPay"
          value={numberToMonetary(weeklyHoursPerPay,2)}
          //onChange={weeklyHoursPerPay}
          placeholder="Weekly Hours Per Pay"
          disabled
        />
      </Col>}
      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="biWeeklyHoursPerPay">Bi-Weekly Hours Per Pay</label>
        <FormInput
          id="biWeeklyHoursPerPay"
          name="biWeeklyHoursPerPay"
          value={numberToMonetary(biWeeklyHoursPerPay,2)}
          onChange={onChange}
          placeholder="Bi-Weekly Hours Per Pay"
          disabled
        />
      </Col>}
      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="semiMonthlyHoursPerPay">Semi-Monthly Hours Per Pay</label>
        <FormInput
          id="semiMonthlyHoursPerPay"
          name="semiMonthlyHoursPerPay"
          value={numberToMonetary(semiMonthlyHoursPerPay,2)}
          onChange={onChange}
          placeholder="Semi-Monthly Hours Per Pay"
          disabled
        />
      </Col>}
      {workerFlag && <Col lg="3" className="form-group m-0 p-2">
        <label htmlFor="monthlyHoursPerPay">Monthly Hours Per Pay</label>
        <FormInput
          id="monthlyHoursPerPay"
          name="monthlyHoursPerPay"
          value={numberToMonetary(monthlyHoursPerPay,2)}
          onChange={onChange}
          placeholder="Monthly Hours Per Pay"
          disabled
        />
      </Col>}



      {/* <Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="hrConsultant">HR Consultant</label>

                    <FormSelect value={hrConsultantId} id="hrConsultantId"
                                name='hrConsultantId' onChange={onChange}
                                className='d-block'>
                      <option value=''>Select HR Consultant
                      </option>
                      <option value='Active'>Active</option>
                    </FormSelect>

                  </Col>

                   */}
      {/* <Col lg="3" className="form-group m-0 p-2">
                    <label htmlFor="subStatus"> Employment Status</label>
                    <FormSelect id="subStatus" value={subStatus}
                                name='subStatus' onChange={onChange}
                                className='d-block'>
                      <option value=''>Select  Employment Status</option>
                      {substatusListOptions}

                    </FormSelect>
                  </Col> */}

    </CustomCard>

  );
}

export default EmploymentInfo;
