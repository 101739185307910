import React, {useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Card, CardBody, CardFooter, CardHeader, Col,
  Container, Form, FormGroup, FormInput, ListGroup,
  ListGroupItem, Nav, NavItem, NavLink, Row
} from "shards-react";
import {connect} from "react-redux";
import {login} from "../redux/actions/user";
import PropTypes from "prop-types";
import {Redirect, useHistory} from "react-router-dom";
import {change_password, reset} from "../redux/actions/settings";
import {setAlert} from "../redux/actions/alert";

function ChangePassword({alerts, user, message,success,reset, change_password, setAlert}) {


  const [formData, setFormData] = useState({
    userId: user.userId,
    userLogin: user.login,
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    tfaCode: '',
    isMinimum8ch:false,
    is1Number:false,
    is1Uppercase:false,
    is1Lowercase:false,
    is1SpecialCh:false,
    isBothMatch:false,
    showCurrentPass:false,
    showNewPass:false,
    showConfirmNewPass:false,
    show2FAPass:false
  });

  

  const passwordStrength = (e) => {
  
    const passwordValue= e.target.value;
    
    const passwordLength= passwordValue.length;

    const LowerRegExp = /[a-z]/;
    const UpperRegExp = /[A-Z]/;
    const NumberRegExp = /(?=.*?[0-9])/;;
    const SpecialCRegExp = /(?=.*?[#!@$%^&*])/;   

    var varMaxMatch=false;
    if(passwordValue.length>=8){
      varMaxMatch=true;
    }
    var varMatch=false;
    if(passwordValue===formData.confirmPassword){
      varMatch=true;
    }
    setFormData({...formData, [e.target.name]: e.target.value,is1Number:NumberRegExp.test(passwordValue),is1Uppercase:UpperRegExp.test(passwordValue),is1Lowercase:LowerRegExp.test(passwordValue),is1SpecialCh:SpecialCRegExp.test(passwordValue),isMinimum8ch:varMaxMatch,isBothMatch:varMatch })
   
  }
  const passwordMatch = (e) => {
    
    const passwordValue= e.target.value;
    
   

   

    var varMatch=false;
    if(passwordValue===formData.newPassword){
      varMatch=true;
    }
    
    setFormData({...formData, [e.target.name]: e.target.value,isBothMatch:varMatch })
   
  }

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = (e) => {
    
    e.preventDefault();
    var passwordIsNotMatching = true;
    if(formData.newPassword !== formData.confirmPassword)
    {
      setAlert("Password is not matching", "danger")
      passwordIsNotMatching = false;
    }
    if(formData.newPassword === formData.currentPassword){
      setAlert("Your New Password cannot be the same as your Current Password.", "danger")
      passwordIsNotMatching = false;
    }
   
    if(passwordIsNotMatching)
      {
        change_password(
          formData.userId,
          formData.userLogin,
          formData.currentPassword,
          formData.newPassword,
          formData.confirmPassword,
          formData.tfaCode
        );
      }
    
  };

  const history=useHistory();

  if(success){    
    history.goBack();
  }

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <>
      <Container fluid
                 className="main-content-container py-4">
        <Row noGutters>
          <Col lg="12" className="col-12">
            <Card>
              <CardBody>
                {/* Form Fields */}
                <Form onSubmit={onSubmit}>
                  <Row>
                  <Col lg="7" className="col-12">
                  <Row>
                    <Col lg="6" className="col-12">
                      <FormGroup>
                        <label htmlFor="currentPassword">Current Password</label>
                        <div className="input-group input-group-seamless"> 
                        <FormInput
                          required
                          className={"form-control rounded-right"}
                          name="currentPassword"
                          type={formData.showCurrentPass?"text":"password"}
                          value={formData.currentPassword}
                          id="currentPassword"
                          placeholder="Enter Current Password"
                          onChange={onChange}
                        />
                         <div className="input-group-append">
                         <span class="input-group-text"> <i onClick={e=>{setFormData({...formData,showCurrentPass:!formData.showCurrentPass})}} className={`fa ${formData.showCurrentPass?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div>
                    </div>
                      </FormGroup>
                    </Col>
                    <Col lg="6" className="col-12">
                      <FormGroup>
                        <label htmlFor="newPassword">New Password</label>
                        <div className="input-group input-group-seamless"> 
                        <FormInput
                          required
                          type={formData.showNewPass?"text":"password"}
                          name="newPassword"
                          className={"form-control rounded-right"}
                          value={formData.newPassword}
                          id="newPassword"
                          placeholder="Enter New Password"
                          onChange={passwordStrength}
                        />
                         <div className="input-group-append">
                         <span class="input-group-text"> <i onClick={e=>{setFormData({...formData,showNewPass:!formData.showNewPass})}} className={`fa ${formData.showNewPass?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div>
                    </div>
                      </FormGroup>
                    </Col>
                    <Col lg="6" className="col-12">
                      <FormGroup>
                        <label htmlFor="confirmPassword">Confirm New Password</label>
                        <div className="input-group input-group-seamless"> 
                        <FormInput
                          required
                          type={formData.showConfirmNewPass?"text":"password"}
                          name="confirmPassword"
                          className={"form-control rounded-right"}
                          value={formData.confirmPassword}
                          id="confirmPassword"
                          placeholder="Confirm New Password"
                          onChange={passwordMatch}
                        />
                         <div className="input-group-append">
                         <span class="input-group-text"> <i onClick={e=>{setFormData({...formData,showConfirmNewPass:!formData.showConfirmNewPass})}} className={`fa ${formData.showConfirmNewPass?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div>
                    </div>
                      </FormGroup>
                    </Col>
                    <Col lg="6" className="col-12">
                      <FormGroup>
                        <label htmlFor="tfaCode">Two-Factor Authentication</label>
                        <div className="input-group input-group-seamless"> 
                        <FormInput
                         type={formData.show2FAPass?"text":"password"}
                          name="tfaCode"
                          className={"form-control rounded-right"}
                          value={formData.tfaCode}
                          id="tfaCode"
                          placeholder="Enter Two-Factor Authentication"
                          onChange={onChange}
                        />
                         <div className="input-group-append">
                         <span class="input-group-text"><i onClick={e=>{setFormData({...formData,show2FAPass:!formData.show2FAPass})}} className={`fa ${formData.show2FAPass?"fa-eye":"fa-eye-slash"} text-secondary`} aria-hidden="true"/></span>
                    </div>
                    </div>
                      </FormGroup>
                    </Col>
                    </Row>

                  </Col>
                  <Col lg="5" className="col-12">
                  <label htmlFor="PasswordParameters">Password Parameters</label>
                  <div className='passwordborder'>
                  <label className={"col-md-6 "} ><small><i className={"pr-2 fa "+ (formData.isMinimum8ch ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>Minimum 8 characters</small></label>
                      <label className={"col-md-6"}><small><i className={"pr-2 fa "+ (formData.is1Number ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>  1 number (0-9)</small></label>
                      <label className={"col-md-6"}><small><i className={"pr-1 fa "+ (formData.is1Uppercase ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i> 1 uppercase letter (A-Z)</small></label>
                      <label className={"col-md-6"}><small><i className={"pr-2 fa "+ (formData.is1Lowercase ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>1 lowercase letter (a-z)</small></label>
                      <label className={"col-md-12 "}><small><i className={"pr-2 fa "+ (formData.is1SpecialCh ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>1 special character (!, @, #, $, %, ^, &, or *)</small></label>
                      <label className={"col-md-12"}><small><i className={"pr-2 fa "+ (formData.isBothMatch ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')}></i>Both passwords match</small></label>
                      </div>
                    </Col>
                  </Row>
                  
                  <div className="d-flex justify-content-center justify-content-lg-end">
                    <Button className="py-2" size="sm" type="submit" disabled={((formData.isBothMatch && formData.isMinimum8ch && formData.is1Number && formData.is1Uppercase && formData.is1Lowercase && formData.is1SpecialCh && formData.newPassword !=='')?false:true)}
                            theme="accent">Change Password</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  message: state.settings.message,
  success: state.settings.success,
  alerts: state.alert,
});
ChangePassword.propTypes = {
  user: PropTypes.object.isRequired,
  change_password: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default connect(mapStateToProps, {change_password,reset, setAlert})(
  ChangePassword
);
