import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {Link, useHistory, useParams} from "react-router-dom";
import ReactTable from "react-table";
import {
  get_country_list, get_region_list, get_holiday_details,
  save_holiday_details,
  delete_holiday,
  clear_holiday_details
} from '../../redux/actions/user';
import {
  Card, CardBody,
  CardHeader, Col,
  Container, ListGroup, ListGroupItem,
  Row
} from "shards-react";
import InputBox from '../../components/common/InputBox';
import {setAPIDate, setInputDate} from '../../utils/date';
import CFormSelect from '../../components/common/CFormSelect';
import DatePicker from 'react-datepicker';
import ConfirmAlert from "../../components/common/ConfirmAlert";

function AddEditHoliday({
                          get_country_list,
                          get_region_list,
                          regionList,
                          countryList,
                          holidayDetails,
                          get_holiday_details,
                          save_holiday_details,
                          delete_holiday,
                          clear_holiday_details
                        }) {
  let {refNum} = useParams();

  useEffect(() => {
    get_country_list();
    return function cleanUp() {
      clear_holiday_details()
    }
  }, []);

  useEffect(() => {
    if (refNum !== '0' && refNum) {
      get_holiday_details(refNum);
    }
  }, [refNum]);

  let history = useHistory();
  const [formData, setFormData] = useState({
    date: '',
    holidayName: '',
    country: '',
    countryId: '0',
    region: '',
    regionId: '0',
    regionRows: [],
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 5,
    fromDate: '',
    toDate: ''

  });
  const [confirmAlert, setConfirmAlert] = useState({
    confirmMsg: "",
    visible: false,
    onConfirm: null
  });
  useEffect(() => {
    if (holidayDetails && Object.keys(holidayDetails).length !== 0 && holidayDetails) {
      setFormData({
        ...formData,
        date: setAPIDate(holidayDetails.date),
        holidayName: holidayDetails.holidayName,
        country: holidayDetails.country,
        region: holidayDetails.region,
        regionRows: holidayDetails.countyRegionList,
      });
    }
  }, [holidayDetails]);

  const {
    date,
    holidayName,
    country,
    countryId,
    region,
    regionId,
    regionRows,
    fromDate,
    toDate
  } = formData;

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSave = async (e) => {
    e.preventDefault()
    let regionListForAPI = [];
    if (regionRows.length !== 0) {
      regionRows.forEach((regionObj) => {
        regionListForAPI.push(regionObj.countryId + '-' + regionObj.regionId);
      });
    } else {
      //regionListForAPI = ["0-0"];
      if(Number(countryId) > 0 ){
        let pairCountryRegion = {
          countryId: countryId,
          countryName: (country === 'Select') ? '' : formData.countryName,
          regionId: regionId,
          regionName: (region === 'Select') ? '' : formData.regionName,
        };
        let sampleRegionListForAPI = [];
        sampleRegionListForAPI.push(pairCountryRegion);
        sampleRegionListForAPI.forEach((regionObj) => {
          regionListForAPI.push(regionObj.countryId + '-' + regionObj.regionId);
        });
      }
      else {
        document.getElementById("btnSubmit").click();
        return false;
      }
    }

    await save_holiday_details(
      refNum,
      setInputDate(date),
      holidayName,
      regionListForAPI,
    );

    history.push('/sub-search-holidays');
  }

  const onDelete = async () => {
    //e.preventDefault();
    setConfirmAlert({
      ...confirmAlert,
      confirmMsg: 'Are you sure you want to delete this holiday?',
      visible: true,
      onConfirm: () => {
        delete_holiday(refNum);
        history.push('/sub-search-holidays');
      }
    })

  }

  const uniqueRows = (rowsArray) => {
    let hashObj = {};
    rowsArray.forEach(pair => {
      hashObj[pair.countryName + "::" + pair.regionName] = pair; //hash created by combining country and region
    });
    return Object.keys(hashObj).map((pair) => hashObj[pair]);
  };

  const onAddCountryRegionPair = (e) => {
    e.preventDefault();

    let pairCountryRegion = {
      countryId: countryId,
      countryName: (country === 'Select') ? '' : formData.countryName,
      regionId: regionId,
      regionName: (region === 'Select') ? '' : formData.regionName,
    };
    
    let tempArr = regionRows;
    tempArr.push(pairCountryRegion);
    
    setFormData({
      ...formData,
      regionRows: uniqueRows(tempArr),
      country: (country == 'Select') ? '' : country,
      region: (region === 'Select') ? '' : region
    });
  };

  const deleteRows = (e, holidayRowObj) => {
    e.preventDefault();
    let tempArray = regionRows.filter((row) => row.regionId !== holidayRowObj);
    setFormData({...formData, regionRows: tempArray});
  }

  if (countryList) {
    var countryDropdown = countryList.map((country) => {
      return <option key={country.countryName + country.countryId} value={country.countryId}>{country.countryName}</option>;
    });
  }
  if (regionList) {
    var regionDropdown = regionList.map((region) => {
      return <option key={region.regionName + region.regionId} value={region.regionId}>{region.regionName}</option>;
    });

    if(regionList.length>0)
      regionDropdown.splice(0, 0, <option key="regionName" value="0">All</option>)
  }

  const tableData = regionRows;
  var {pageSize, pageSizeOptions} = formData;

  const tableColumns = [
    {
      Header: "Country",
      accessor: "countryName",
      maxWidth: 400,
      className: "text-center"
    },
    {
      Header: "Region Selected",
      accessor: "regionName",
      maxWidth: 500,
      className: "text-center",
      Cell: row => (row.original.regionName) ? row.original.regionName : "All"
    },
    {
      Header: "Action",
      accessor: "regionId",
      className: "text-center",
      Cell: row =>
        <Link onClick={(e) => deleteRows(e, row.original.regionId)}>Delete</Link>
    }
  ];

  return (

    <Container fluid className="main-content-container px-4">
      <ConfirmAlert confirmAlert={confirmAlert}
                    setConfirmAlert={setConfirmAlert}/>
      <form onSubmit={onAddCountryRegionPair}>
        <Row className='m-0 mt-4'>
          <Card small className=" mb-4 w-100 d-flex personalParent">
            <h4 className="m-0 section-title idStyle">Ref# {refNum}</h4>
            <ListGroup flush>
              <ListGroupItem className="border-card p-3 mt-3">
                <Row>
                  <Col>

                      <Row form>
                        <Col lg="3" className="form-group p-2 mb-0">
                          <label htmlFor="AddEditDate">Date</label>
                          <span aria-hidden="true" className="required"> *</span>
                          <DatePicker className='form-control'
                                      id='date'
                                      autoComplete='off'
                                      name='date'
                                      dateFormat='yyyy-MM-dd'
                                      selected={date}
                                      value={date}
                                      placeholderText='YYYY-MM-DD'
                                      required
                                      onChange={(dateVal) =>
                                        setFormData({
                                          ...formData,
                                          date: dateVal
                                        })
                                      }
                                      style={{border: "1px solid #ced4da"}}
                          />
                        </Col>

                        <InputBox placeholder="Enter Holiday Name"
                                  label="Holiday Name" name='holidayName'
                                  id="holidayName"
                                  required
                                  value={holidayName}
                                  onChange={onChange}/>
                        <CFormSelect name='countryId'
                                     required
                                     defaultOptionName="Select Country Name"
                                     value={countryId} onChange={(e) => {
                          get_region_list(e.target.value);
                          setFormData({
                            ...formData,
                            countryId: e.target.value,
                            countryName: e.target.options[e.target.selectedIndex].innerHTML
                          });
                        }} label="Country" data={countryDropdown}/>
                        <CFormSelect name='regionId'
                                     required
                                     defaultOptionName="Select Province"
                                     value={regionId}
                                     label="State/Province/Region"
                                     data={regionDropdown} onChange={(e) => {

                          setFormData({
                            ...formData,
                            regionId: e.target.value,
                            regionName: e.target.options[e.target.selectedIndex].innerHTML
                          });
                        }}/>
                        <Col
                          className="d-flex justify-content-end form-group p-2 mb-0">
                          <div className="d-flex justify-content-between">
                            <button type="submit" id="btnSubmit"
                                    className="btn btn-primary w-100">Add</button>
                          </div>
                        </Col>
                      </Row>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>


        </Row>
        <Card className="tableCard p-0" style={{borderRadius: "0.625rem"}}>
          <CardHeader></CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={tableData}
                pageSize={Number(pageSize)}
                showPageSizeOptions={false}
                resizable={false}
                noDataText="No results found"
              />
            </div>
          </CardBody>
        </Card>
        <Col className="d-flex justify-content-end form-group py-2 mb-4">
          <button onClick={onSave} className="btn btn-primary">Save</button>
          {refNum !== '0' && (
            <button type="button" className='btn btn-primary ml-2'
                    onClick={onDelete}>Delete</button>
          )}
          <button onClick={() =>
            history.push('/sub-search-holidays')
          } className="btn btn-primary ml-2">Cancel
          </button>
        </Col>
      </form>
    </Container>
  );
}


AddEditHoliday.propTypes = {
  user: PropTypes.object.isRequired,
  holidayDetails: PropTypes.object.isRequired,
  countryList: PropTypes.array.isRequired,
  regionList: PropTypes.array.isRequired,
  get_country_list: PropTypes.func.isRequired,
  get_region_list: PropTypes.func.isRequired,
  get_holiday_details: PropTypes.func.isRequired,
  save_holiday_details: PropTypes.func.isRequired,
  delete_holiday: PropTypes.func.isRequired,
  clear_holiday_details: PropTypes.func.isRequired,
  subHolidayList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  holidayDetails: state.user.holidayDetails,
  countryList: state.user.countryList,
  regionList: state.user.regionList,
  subHolidayList: state.user.subHolidayList,
});

export default connect(mapStateToProps, {
  get_country_list,
  get_region_list,
  get_holiday_details,
  save_holiday_details,
  delete_holiday,
  clear_holiday_details
})(AddEditHoliday);

