export default function () {
  return [
    {
      roles:['Worker'],
      items: [
        {
          title: 'Home',
          to: '/worker-home',
          //htmlBefore: '<i class="material-icons">home</i>',
          htmlBefore: '',
          htmlAfter: '',
        },

        {
          title: 'Profile',
          to: '/view-profile',
          //htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
          htmlBefore: '',
          htmlAfter: '',
        },

        {
          title: 'Leaves',
          to: '/manage-leaves',
          //htmlBefore: '<i class="material-icons">today</i>',
          htmlBefore: '',
          htmlAfter: '',
          permission:'Leaves Module',
          activeRoutes:[
            '/edit-leave/',
            '/add-leave/',
          ]
        },

        {
          title: 'Expenses',
          to: '/view-expenses',
          //htmlBefore: '<i class="material-icons">receipt_long</i>',
          htmlBefore: '',
          htmlAfter: '',
          permission:'Expense Module',
          activeRoutes:[
            '/add-expense-report/',
            '/edit-expense-report/',
            '/add-expense-item/',
            '/edit-expense-item/',
            '/add-mileage-item/',
            '/edit-mileage-item/',
          ]
        },

        {
          title: 'Message Center',
          to: '/message-center',
          //htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            '/user-edit-message/'
          ]
        },

        {
          title: 'Product Feedback',
          to: '/user-add-message/0/Product Feedback',
          //htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            
          ]
        }
      ]
    },
    {
      roles:['Client Admin','Client Billing','Client HR', 'Client User'],
      title:'Client',
      items:[
        {
          title: 'Home',
          to: '/client-home',
          //htmlBefore: '<i class="material-icons">home</i>',
          htmlBefore: '',
          htmlAfter: '',
        },
        {
          title: 'Profile',
          to: '/client-view-profile',
          //htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
          htmlBefore: '',
          htmlAfter: '',
        },
        {
          title: 'Workers',
          to: '/client-search-clientWorkers',
          //htmlBefore: '<i class="material-icons">groups</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes:[
            '/client-view-worker-profile/'
          ]
        },

        {
          title: 'Leaves',
          to: '/client-search-leaves',
          //htmlBefore: '<i class="material-icons">today</i>',
          htmlBefore: '',
          htmlAfter: '',
          permission:'Leaves Module',
          activeRoutes:[
            '/client-view-worker-leave/',
          ]
        },

        {
          title: 'Expenses',
          to: '/client-search-expenses',
          //htmlBefore: '<i class="material-icons">receipt_long</i>',
          htmlBefore: '',
          htmlAfter: '',
          permission: 'Expense Module',
          activeRoutes: [
            '/client-view-expense-sheet/',
            '/client-view-expense-item/',
            '/client-view-mileage-item/',
          ]
        },

        // {
        //   title: 'Variable Pay',
        //   to: '/client-search-variablepay',
        //   htmlBefore: '<i class="material-icons">receipt_long</i>',
        //   activeRoutes:[
        //     '/client-view-worker-leave/',
        //   ]
        // },

        {
          title: 'Reports',
          to: '/reports',
          //htmlBefore: '<i class="material-icons">insert_chart</i>',
          htmlBefore: '',
          htmlAfter: '',
        },

        {
          title: 'Message Center',
          to: '/client-message-center',
          //htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            '/client-edit-message/'
          ]
        },

        {
          title: 'Product Feedback',
          to: '/client-add-message/0/Product Feedback',
          //htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
          ]
        }

        ]
    },

    {
      roles:['Subscriber Admin','Subscriber User','Subscriber HR','Subscriber Billing'],
      title:'Subscriber',
      items: [
        {
          title: 'Home',
          to: '/sub-home',
          //htmlBefore: '<i class="material-icons">home</i>',
          htmlBefore: '',
          htmlAfter: ''
        },

        {
          title: 'Users',
          to: '/sub-search-users',
          //htmlBefore: '<i class="material-icons">groups</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            '/manage-user-profile/',
            '/sub-edit-leave/',
            '/sub-edit-expense-reports/',
            '/sub-add-expense-item/',
            '/sub-add-mileage-item/',
            '/sub-add-edit-notes',
            '/sub-manage-permissions'
          ]
        },

        {
          title: 'Leaves',
          to: '/sub-search-leaves',
          //htmlBefore: '<i class="material-icons">today</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes:[
            '/sub-approve-leave/',
          ]
        },

        {
          title: 'Expenses',
          to: '/sub-search-expenses',
          //htmlBefore: '<i class="material-icons">receipt_long</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            '/sub-approve-expense-report/',
            '/sub-approve-expense-item/',
            '/sub-approve-mileage-item/',
          ]
        },
        // {
        //   title: 'Variable Pay',
        //   to: '/subscriber-search-variablepay',
        //   htmlBefore: '<i class="material-icons">receipt_long</i>',
        //   htmlAfter: '',
        // },
        {
          title: 'Clients',
          to: '/sub-search-clients',
          //htmlBefore: '<i class="material-icons">people</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            '/sub-manage-client-profile/',
            '/sub-client-customize-leave-policy/',
          ]
        },

        {
          title: 'Message Center',
          to: '/sub-message-center',
          //htmlBefore: '<i class="material-icons">question_answer</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            '/sub-message-activity/'
          ]
        },

        {
          title: 'Data Approvals',
          roles:["Subscriber Admin", "Subscriber Billing"],
          htmlBefore: '',
          activeRoutes:[
            '/sync-approval',
            '/sync-history'
          ],
          open: false,
          items: [
            {
              title: 'Sync Approvals',
              to: '/sync-approval',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes:[
                '/sync-approval',
              ]
            },
            {
              title: 'Sync History',
              to: '/sync-history',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes:[
                '/sync-history/',
              ]
            },
          ],
        },

        // {
        //   title: 'Proposal',
        //   to: '/sub-search-proposal',
        //   htmlBefore: '<i class="material-icons">description</i>',
        //   htmlAfter: '',
        //   activeRoutes: [
        //     '/proposal-form/'
        //   ]
        // },
        {
          title: 'Invoices',
            roles: ["Subscriber Admin", "Subscriber Billing"],
            activeRoutes: [
              '/view-invoices',
              '/new-invoice/',
              '/add-edit-invoice-item',
              '/export-invoices'

            ],
                open: false,
                  items: [
                    {
                      title: 'View Invoices',
                      to: '/view-invoices',
                      htmlBefore: '<i></i>',
                      htmlAfter: '',
                      activeRoutes: [
                        '/view-invoices'
                      ]
                    },
                    {
                      title: 'New Invoice',
                      to: '/new-invoice',
                      htmlBefore: '<i></i>',
                      htmlAfter: '',
                      activeRoutes: [
                        '/new-invoice/',
                        '/add-edit-invoice-item'
                      ]
                    },
                    {
                      title: 'Export Invoices',
                      to: '/export-invoices',
                      htmlBefore: '<i></i>',
                      htmlAfter: '',
                      activeRoutes: [
                        '/export-invoices/'
                      ]
                    }
                  ],
         },
         {
          title: 'Payables',
            roles: ["Subscriber Admin", "Subscriber Billing"],
            activeRoutes: [
              //To Do --> Need to replace URL too
              '/view-payables',
              '/payable/',
              '/add-edit-payable-item/'
            ],
                open: false,
                  items: [  
                    {
                      title: 'View Payables',
                      to: '/view-payables',
                      htmlBefore: '<i></i>',
                      htmlAfter: '',
                      activeRoutes: [
                        '/view-payables/'
                      ]
                    },
                    {
                      title: 'New Payable',
                      to: '/payable',
                      htmlBefore: '<i></i>',
                      htmlAfter: '',
                      activeRoutes: [
                        '/payable',
                        '/add-edit-payable-item/' 
                      ]
                    }
                  ],
         },
        {
          title: 'Payments',
          roles: ["Subscriber Admin", "Subscriber Billing"],
          open: false,
          activeRoutes: [
            '/view-payments/',
            '/new-payment/',
            '/add-edit-payment-allocation-item/'
          ],
          items: [
            {
              title: 'View Payments',
              to: '/view-payments/',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes: [
                '/view-payments/'
              ]
            },
            {
              title: 'New Payment',
              to: '/new-payment',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes: [
                '/new-payment/',
                '/add-edit-payment-allocation-item/'
              ]
            },
          ],
        },
        {
          title: 'Automation',
          roles: ["Subscriber Admin", "Subscriber Billing"],
          open: false,
          items: [
            {
              title: 'Generate Invoices & Payments',
              to: '/generate-invoices-and-payments/',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes: [
                '/generate-invoices-and-payments/'
              ]
            }
          ],
        },
        {
          title: 'Reports',
          to: '/sub-generate-reports',
          //htmlBefore: '<i class="material-icons">table_chart</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            '/sub-search-Reports/'
          ]
        },
        {
          title: 'Import Data',
          roles: ["Subscriber Admin", "Subscriber Billing"],
          open: false,
          activeRoutes: [
            '/import-data ',
            '/view-payroll-tax'
          ],
          items: [
            {
              title: 'Import Data',
              to: '/import-data/',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes: [
                '/import-data/'
              ]
            },
            {
              title: 'View Payroll Tax Data',
              to: '/view-payroll-tax',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes: [
                '/view-payroll-tax/'
              ]
            },
          ],
        },
        {
          title: 'Settings',
          roles:["Subscriber Admin"],
          //htmlBefore: '<i class="material-icons">handyman</i>',
          htmlBefore: '',
          activeRoutes:[
            '/sub-add-edit-mileage-range/',
            '/sub-add-edit-expense-type/',
            '/sub-add-edit-allowance-type/',
            '/sub-search-holidays',
            '/sub-search-leave-policy',
            '/sub-edit-leave-policy/',
            '/sub-leave-policy-location-config/',
            '/sub-add-edit-mileage-range/',
            '/sub-add-edit-expense-type/',
            '/sub-add-edit-allowance-type/',
            '/subscriber-payschedule',
            '/subscriber-paycomponent',
            '/sub-add-edit-pay-component/',
            '/sub-add-edit-pay-schedule/',
            '/subscriber-paycomponent',
            '/sub-add-edit-pay-component/',
            '/payee-configuration',
            '/payer-configuration'
          ],
          open: false,
          items: [
            {
              title: 'Holiday',
              to: '/sub-search-holidays',
              //htmlBefore: '<i class="material-icons">beach_access</i>',
              htmlBefore: '<i</i>',
              htmlAfter: '',
              activeRoutes:[
                '/sub-add-edit-holiday/',
              ]
            },
            {
              title: 'Leave Policy',
              to: '/sub-search-leave-policy',
              //htmlBefore: '<i class="material-icons">today</i>',
              // For spacing inner item 
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes:[
                '/sub-edit-leave-policy/',
                '/sub-leave-policy-location-config/',
              ]
            },
            {
              title: 'Configuration',
              to: '/sub-configuration',
              //htmlBefore: '<i class="material-icons">settings</i>',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes:[
                '/sub-add-edit-mileage-range/',
                '/sub-add-edit-expense-type/',
                '/sub-add-edit-allowance-type/',

              ]
            },
            {
              title: 'Payroll',
              to: '',
              htmlBefore: '<i class=""></i>',
              open: false,
              activeRoutes:[
                '/subscriber-payschedule',
                '/subscriber-paycomponent',
                '/sub-add-edit-pay-component/',
                '/sub-add-edit-pay-schedule/'
              ],
              items: [
                {
                  title: 'New Pay Component',
                  to: '/subscriber-paycomponent',
                  htmlBefore: '<i></i>',
                  htmlAfter: '',
                  activeRoutes:[
                    '/subscriber-paycomponent',
                    '/sub-add-edit-pay-component/',
                  ]
                },
                {
                  title: 'Pay Schedule',
                  to: '/subscriber-payschedule',
                  htmlBefore: '<i></i>',
                  htmlAfter: '',
                  activeRoutes:[
                    '/subscriber-payschedule',
                    '/sub-add-edit-pay-schedule/'
                  ]
                },
              ]
            },

            {
              title: 'Rate Tables',
              to: '',
              htmlBefore: '<i class=""></i>',
              open: false,
              activeRoutes:[
                //ToDo  
                '/worker-comp-insurance',
                '/worker-comp-insurance-add-edit/',
                '/health-tax',
                '/health-tax-add-edit/',
                '/government-pension',
                '/government-pension-add-edit/',
                '/government-pension2',
                '/government-pension2-add-edit/',
                '/employment-insurance',
                '/employment-insurance-add-edit/',
              ],
              items: [
                {
                  title: 'Worker Comp Insurance',
                  to: '/worker-comp-insurance',
                  htmlBefore: '<i></i>',
                  htmlAfter: '',
                  activeRoutes:[
                    '/worker-comp-insurance',
                    '/worker-comp-insurance-add-edit/',
                  ]
                },
                {
                  title: 'Health Tax',
                  to: '/health-tax',
                  htmlBefore: '<i></i>',
                  htmlAfter: '',
                  activeRoutes:[
                    '/health-tax',
                    '/health-tax-add-edit/'
                  ]
                },
                {
                  title: 'Government Pension',
                  to: '/government-pension',
                  htmlBefore: '<i></i>',
                  htmlAfter: '',
                  activeRoutes:[
                    '/government-pension',
                    '/government-pension-add-edit/'
                  ]
                },
                {
                  title: 'Government Pension 2',
                  to: '/government-pension2',
                  htmlBefore: '<i></i>',
                  htmlAfter: '',
                  activeRoutes:[
                    '/government-pension2',
                    '/government-pension2-add-edit/'
                  ]
                },
                {
                  title: 'Employment Insurance',
                  to: '/employment-insurance',
                  htmlBefore: '<i></i>',
                  htmlAfter: '',
                  activeRoutes:[
                    '/employment-insurance',
                    '/employment-insurance-add-edit/'
                  ]
                },
              ]
            },

            {
              title: 'Payee',
              to: '/payee-configuration',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes:['/payee-configuration']
            },
            {
              title: 'Payer',
              to: '/payer-configuration',
              htmlBefore: '<i></i>',
              htmlAfter: '',
              activeRoutes:['/payer-configuration']
            },
          ],
        },
      ]
    },


    {
      roles:['System Admin'],
      title:'System Admin',
      activeRoutes: [
        '/admin-manage-subscriber-profile/',
        '/admin-add-edit-currency/',
        '/admin-add-edit-location/',
      ],
      items:[
        // {
        //   title: 'Users',
        //   to: '/admin-search-users',
        //   htmlBefore: '<i class="material-icons">group</i>',
        //   htmlAfter: '',
        //   activeRoutes: [
        //     '/admin-manage-user-profile/',
        //     '/admin-manage-client-profile/',
        //   ]
        // },

        {
          title: 'Subscriber',
          to: '/admin-search-subscribers',
          //htmlBefore: '<i class="material-icons">manage_accounts</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            '/admin-manage-subscriber-profile/',
          ]
        },

        {
          title: 'Configuration',
          to: '/admin-configuration',
          //htmlBefore: '<i class="material-icons">settings</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: [
            '/admin-add-edit-currency/',
            '/admin-add-edit-location/',
          ]
        },

        {
          title: 'Audit Log',
          to: '/admin-auditlog',
          //htmlBefore: '<i class="material-icons">history</i>',
          htmlBefore: '',
          htmlAfter: '',
          activeRoutes: ['/admin-auditlog']
        }
        ]
    },
  ];
}
