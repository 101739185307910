import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bulk_delete_payment, bulk_update_payment_status, get_payment_status_list, get_view_payment_list, reset_payment_view } from '../../../redux/actions/subscriber/payments';
import RangeDatePicker from '../../../components/common/RangeDatePicker';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormInput, FormSelect, Modal, ModalBody, Row } from 'shards-react';
import CustomTable from '../../../components/common/CustomTable';
import { setDateForTable, setInputDate } from '../../../utils/date';
import { get_currency_list } from '../../../redux/actions/subscriber/invoice';
import Multiselect from 'multiselect-react-dropdown';
import { formatNumberAmount } from '../../../utils/general';
import ReactTable from 'react-table';
import { setAlert } from '../../../redux/actions/alert';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NavgationPageLocationKey } from '../../../utils/constants';

const ViewPayments = ({ paymentHistoryList, get_view_payment_list, paymentStatusList
    , get_payment_status_list, get_currency_list, currencyList, bulkUpdate
    , bulk_update_payment_status, bulk_delete_payment }) => {

    const CHANGE_STATUS = 'CHANGE_STATUS';
    const DELETE_PAYMENT = 'DELETE_PAYMENT';
    const initialData = {
        paymentRefNum: '',
        payer: '',
        payee: '',
        status: '',
        currencies: '',
        from: '',
        to: '',
        pageSizeOptions: [5, 10, 15, 20, 25, 30],
        pageSize: 10,
        bulkAction: '',
    }
    const [formData, setFormData] = useState(initialData);
    const [dropDownStatus, setDropDownStatus] = useState('');
    const { pageSize, pageSizeOptions } = formData;
    const [gridData, setGridData] = useState(paymentHistoryList);

    const currencyListOptions = (currencyList || []).map(currency => <option key={currency} value={currency}>{currency}</option>)
    const paymentStatusOptions = (paymentStatusList || []).map(status => <option key={status} value={status}>{status}</option>)

    const defaultStatus = [{ cat: 'Pending', key: 'Pending' }];
    const [statusDropdownOptions, setStatusDropdownOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([...defaultStatus]);
    const tableRef = useRef()

    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();

    const toggle = () => {
        setOpenModal(!openModal);
        setFormData({ ...formData, bulkAction: '' });
        reset_payment_view();
        setDropDownStatus('')
    }

    useEffect(() => {
        const selectedStatus = selectedValues.map(status => status.key).toLocaleString()
        get_view_payment_list('', '', '', selectedStatus, '', 0, 0);
        get_payment_status_list();
        get_currency_list();
    }, [])

    useEffect(() => {
        setGridData(paymentHistoryList.map(x => { x.checked = false; return x; }));
    }, [paymentHistoryList])

    useEffect(() => {
        let paymentStatus = (paymentStatusList || []).map(status => ({
            cat: status, key: status
        }))

        setStatusDropdownOptions([{
            cat: 'All',
            key: 'All'
        }, ...paymentStatus])
    }, [paymentStatusList])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    let onStartDateChanged = (value) => {
        setFormData({ ...formData, from: value })
    }

    let onEndDateChanged = (value) => {
        setFormData({ ...formData, to: value })
    }

    const isAllChecked = () => {
        if (gridData === undefined || gridData.length === 0) return false

        for (let data of gridData) {
            if (!data.checked)
                return false;
        }
        return true;
    }

    const onCheck = (refNum) => {
        let checkedTableData = gridData.map(data => {
            if (data.paymentRefNum === refNum)
                data.checked = !data.checked
            return data;
        })
        setGridData(checkedTableData);
    }

    const checkAll = (e) => {
        let checkedTableData = gridData.map(data => {
            data.checked = e.target.checked;
            return data;
        })
        setGridData(checkedTableData);
    }

    function searchPaymentHistory(e) {
        e.preventDefault();
        const selectedStatus = selectedValues.map(status => status.key).toLocaleString()
        const fromDate = setInputDate(formData.from);
        const toDate = setInputDate(formData.to);
        let splitedRef = formData.paymentRefNum.split('-');
        const refNum  = splitedRef.length > 1 ? splitedRef[1] : formData.paymentRefNum;
        get_view_payment_list(refNum, formData.payer, formData.payee, selectedStatus, formData.currencies, fromDate, toDate);
    }

    function resetPaymentHistory() {
        setFormData(initialData);
        const selectedStatus = defaultStatus.map(status => status.key).toLocaleString()
        setSelectedValues([...defaultStatus])
        get_view_payment_list('', '', '', selectedStatus, '', 0, 0);
        get_payment_status_list();
        get_currency_list();
    }

    const tableColumns = [
        {
            Header: <input type="checkbox" checked={isAllChecked()} onClick={(e) => checkAll(e)} />,
            accessor: "action", maxWidth: 50, minWidth: 50, sortable: false,
            Cell: row => <input type="checkbox" checked={row.original.checked} key={row.original.refNum} onClick={() => onCheck(row.original.paymentRefNum)} />
        },
        {
            Header: "Ref #", accessor: "paymentRefNum", className: "text-center",
            maxWidth: 130, minWidth: 90,
            // Cell: row => <Link to={`/new-payment/${row.original.paymentRefNum}`}>{`PMT-${row.original.paymentRefNum}`}</Link>
            Cell: row => <a href="/new-payment" onClick={(e) => {navigateToPayment(e, row.original.paymentRefNum)}}
                onContextMenu={(e) => onContextMenuPaymentChange(e, row.original.paymentRefNum)}>
                {`PMT-${row.original.paymentRefNum}`}
           </a>
        },
        {
            Header: "Date", accessor: "recordDate", className: "text-center",
            maxWidth: 140, minWidth: 100,
            Cell: row => setDateForTable(row.original.recordDate)
        },
        {
            Header: "Payer", accessor: "payer", className: "justify-content-start"
        },
        {
            Header: "Payee", accessor: "payee", className: "text-center"
        },
        {
            Header: "Status", accessor: "status", className: "text-center",
            maxWidth: 140, minWidth: 100,
        },
        {
            Header: "Currency", accessor: "currency", className: "text-center",
            maxWidth: 120, minWidth: 80,
        },
        {
            Header: "Amount", accessor: "amount", className: "text-center",
            Cell: row => <span className="w-100 text-right">{formatNumberAmount(row.original.amount, 'en-US', 2)}</span>
        },
    ];

    useEffect(() => {
        if (formData.bulkAction !== '') {
            if (gridData !== undefined && gridData.length > 0) {
                var selectedData = gridData.filter(x => { return x.checked === true });
                if (selectedData.length > 0) {
                    setOpenModal(formData.bulkAction === CHANGE_STATUS || formData.bulkAction === DELETE_PAYMENT)
                }
                else {
                    setAlert("Please select records to perform actions", 'danger');
                    setFormData({ ...formData, bulkAction: '' });
                }
            }
            else {
                setAlert("Please select records to perform actions", 'danger');
                setFormData({ ...formData, bulkAction: '' });
            }
        }
    }, [formData.bulkAction])

    const onSubmit = async (e) => {
        e.preventDefault();
        let paymentRefList = gridData.filter(data => data.checked).map(mapData => mapData.paymentRefNum);
        if (e.nativeEvent.submitter.name === 'set') {
            let updated = await bulk_update_payment_status({ refList: [...paymentRefList], status: dropDownStatus });
            if (updated) {
                recallFilteredData();
            }
        }
        else {
            // Bulk Delete
            let updated = await bulk_delete_payment([...paymentRefList]);
            if (updated) {
                recallFilteredData();
            }
        }
        toggle();
    }

    function recallFilteredData() {
        const selectedStatus = selectedValues.map(status => status.key).toLocaleString();
        get_view_payment_list( formData.paymentRefNum, formData.payer, formData.payee
            , selectedStatus, formData.currency, setInputDate(formData.from), setInputDate(formData.to));  
    }

    // Added by nipul for parameter less flow
    function navigateToPayment(e, refId){
        e.preventDefault(); 
        history.push({
            pathname: `/new-payment`,
            state: { refNum: refId }
        });
        return false;
    }

    function onContextMenuPaymentChange(e, refId){
        if(e.type === 'contextmenu'){
            localStorage.setItem(NavgationPageLocationKey.PaymentCurrentAddEdit, refId);
        }
    }

    return (
        <Container fluid className="main-content-container p-4">

            <Modal size="md" fade={false} style={{ width: '500px' }} backdrop={false} className="d-flex align-items-center justify-content-center w-auto h-100" open={openModal} toggle={toggle}>
                <div className="d-flex justify-content-end p-1 mx-2"><i class="fa-solid fa-xmark primary-text bg-white" role="button" onClick={toggle}></i></div>
                <ModalBody className={!bulkUpdate ? "px-4" : 'px-0'}>
                    <div>
                        <Form onSubmit={onSubmit}>
                            {formData.bulkAction === CHANGE_STATUS ?
                                !bulkUpdate ?
                                    <Row className="w-100">
                                        <Col lg="6" className="my-auto pb-4">
                                            <label className="h4 font-weight-bold my-auto">Set Status:</label>
                                        </Col>
                                        <Col lg="6" className="px-0 pb-4">
                                            <FormSelect name='setStatus'
                                                value={dropDownStatus}
                                                onChange={(e) => setDropDownStatus(e.target.value)}
                                            >
                                                <option value=''>Select Status</option>
                                                {paymentStatusOptions}
                                            </FormSelect>
                                        </Col>
                                        <Col lg="6"></Col>
                                        <Col lg="6" className="d-flex flex-row justify-content-between px-0">
                                            <Button name="set" type="submit" className="px-4">Set</Button>
                                            <Button name="cancel" onClick={toggle} className="px-4">Cancel</Button>
                                        </Col>
                                    </Row> :
                                    <Row className="w-100 pb-2 d-flex flex-row justify-content-center pl-4">
                                        <Col lg="12" className="my-auto pb-4 tex-center pl-2 px-0">
                                            <label className="font-weight-bold my-auto">The status has been changed to <span><label className="primary-text">{formData.status}</label></span> for the selected items</label>
                                        </Col>
                                        <Col lg="12" className="d-flex flex-row justify-content-center">
                                            <div className="d-flex flex-row justify-content-between">
                                                <Button name="cancel" onClick={toggle} className="px-4 mx-2">Close</Button>
                                            </div>
                                        </Col>

                                    </Row>
                                : !bulkUpdate ?
                                    <Row className="w-100 pb-2 d-flex flex-row justify-content-center pl-4">
                                        <Col lg="12" className="my-auto pb-4 tex-center">
                                            <label className="font-weight-bold my-auto">Are you sure you want to delete all selected Payment(s)?</label>
                                        </Col>
                                        <Col lg="12" className="d-flex flex-row justify-content-center">
                                            <div className="d-flex flex-row justify-content-between">
                                                <Button name="yes" type="submit" className="px-4 mx-2">Yes</Button>
                                                <Button name="cancel" onClick={toggle} className="px-4 mx-2">Cancel</Button>
                                            </div>
                                        </Col>

                                    </Row>
                                    :
                                    <Row className="w-100 pb-2 d-flex flex-row justify-content-center pl-4">
                                        <Col lg="12" className="my-auto pb-4 text-center pl-3">
                                            <label className="font-weight-bold my-auto">This feature is currently not available.</label>
                                        </Col>
                                        <Col lg="12" className="d-flex flex-row justify-content-center">
                                            <div className="d-flex flex-row justify-content-between">
                                                <Button name="cancel" onClick={toggle} className="px-4 mx-2">Close</Button>
                                            </div>
                                        </Col>

                                    </Row>
                            }
                        </Form>
                    </div>
                </ModalBody>
            </Modal>

            <div noGutters
                className="page-header flex-column flex-md-row py-2 d-flex justify-content-end bg-white tableSearch">

                <div className="d-flex align-items-center p-1 ml-2">
                    <FormInput name='paymentRefNum' value={formData.paymentRefNum} size='50px'
                        onChange={onChange} placeholder="Ref #" />
                </div>

                <div className="d-flex align-items-center p-1">
                    <FormInput name='payer' value={formData.payer}
                        onChange={onChange} placeholder="Payer" />
                </div>

                <div className="d-flex align-items-center p-1">
                    <FormInput name='payee' value={formData.payee}
                        onChange={onChange} placeholder="Payee" />
                </div>

                <div className="d-flex align-items-center p-1">
                    <Multiselect
                        hideSelectedList
                        displayValue="key"
                        placeholder={`Status: ${(selectedValues && selectedValues.length > 0 && selectedValues[0].key === 'All') ? 'All' : selectedValues.map(val => val.key).toString()}`}
                        onKeyPressFn={function noRefCheck() { }}
                        onSearch={function noRefCheck() { }}
                        onRemove={function noRefCheck(selectedOptions, item) {
                            if (item.key === 'All') {
                                setSelectedValues([])
                            } else {
                                selectedOptions = selectedOptions.filter(option => option.key !== 'All')
                                setSelectedValues([...selectedOptions])
                            }
                        }}
                        onSelect={function noRefCheck(selectedOptions, seletedItem) {
                            if (seletedItem.key === 'All') {
                                setSelectedValues([...statusDropdownOptions])
                            } else {
                                if (selectedOptions.length === statusDropdownOptions.length - 1) {
                                    selectedOptions = [...statusDropdownOptions]
                                }
                                setSelectedValues([...selectedOptions])
                            }
                        }}
                        options={[
                            ...statusDropdownOptions
                        ]}
                        selectedValues={[...selectedValues]}

                        showCheckbox />
                </div>

                <div className="d-flex align-items-center p-1">
                    <FormSelect
                        id="currencies"
                        name="currencies"
                        value={formData.currencies}
                        onChange={onChange}>
                        <option key={`status`} value=''>All Currencies</option>
                        {currencyListOptions}
                    </FormSelect>
                </div>
                <div className="d-flex align-items-center p-1">
                    <RangeDatePicker className="justify-content-end" name="range"
                        startDate={formData.from} endDate={formData.to}
                        onStartDateChanged={onStartDateChanged} onEndDateChanged={onEndDateChanged} />
                </div>

                <div className="d-flex align-items-center justify-content-end p-1">
                    <Button className="mx-2 py-2 my-2" size='sm' theme="accent"
                        onClick={searchPaymentHistory}>Search</Button>

                    <Button onClick={resetPaymentHistory} className="mx-2 py-2 my-2"
                        size='sm' theme="accent">Reset</Button>
                </div>
            </div>
            <div className="w-100">
                <Card className="tableCard p-0">
                    <CardHeader className="p-0">
                        <Container fluid className="file-manager__filters border-bottom">
                            <Row>
                                {/* Filters :: Page Size */}
                                <Col lg="12"
                                    className="file-manager__filters__rows d-flex justify-content-between">
                                    <span>
                                        <FormSelect size="sm" name='bulkAction'
                                            value={formData.bulkAction} onChange={onChange}
                                        >
                                            <option key="bulkActions0" value="">Bulk Actions</option>
                                            <option key="changeStatus1" value={CHANGE_STATUS}>Change Status</option>
                                            <option key="delete2" value={DELETE_PAYMENT}>Delete</option>
                                        </FormSelect>
                                    </span>
                                    <span><span>Show</span>
                                        <FormSelect size="sm" name='pageSize'
                                            value={formData.pageSize} className="d-inline"
                                            onChange={onChange}
                                        >
                                            {pageSizeOptions.map((size, idx) => (
                                                <option key={idx} value={size}>
                                                    {size} rows
                                                </option>
                                            ))}
                                        </FormSelect>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="">
                            <ReactTable
                                ref={tableRef}
                                columns={tableColumns}
                                data={gridData}
                                pageSize={Number(pageSize)}
                                showPageSizeOptions={false}
                                resizable={false}
                                noDataText="No results found"
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    paymentHistoryList: state.payment.paymentHistoryList,
    paymentStatusList: state.payment.paymentStatusList,
    currencyList: state.invoice.currencyList,
});

export default connect(mapStateToProps, { get_view_payment_list, get_payment_status_list, get_currency_list
    , bulk_update_payment_status, bulk_delete_payment })
    (ViewPayments)
