import { Button, Card, Col, Container, DatePicker, Form, FormInput, FormSelect, ListGroup, ListGroupItem, Row } from "shards-react";
import { NavgationPageLocationKey, STANDARD_FORMATS } from "../../../utils/constants";
import StandardFormInputFreeWidth from "../../../components/common/StandardFormInputFreeWidth";
import { compareObjects, formatNumberAmount } from "../../../utils/general";
import { useEffect, useRef, useState } from "react";
import { setAPIDate, setDateForTable, setInputDate } from "../../../utils/date";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmAlert from "../../../components/common/ConfirmAlert";
import { connect } from "react-redux";
import { delete_pay_record_item, download_pay_record, get_pay_record, get_pay_record_status_list, reset_pay_record, save_pay_record } from "../../../redux/actions/subscriber/payRecord";
import { get_currency_list } from "../../../redux/actions/subscriber/invoice";
import { get_payee_configuration_list, get_payee_dropdown_list } from "../../../redux/actions/subscriber/payConfiguration";
import Dropdown from "../../../components/common/Dropdown";
import { setAlert } from "../../../redux/actions/alert";
import { get_base_currency } from "../../../redux/actions/user";
import ViewIncomingAndOutGoingPayments from "./ViewIncomingAndOutgoingPayments";
import { get_payments } from "../../../redux/actions/subscriber/payments";

const PayRecord = ({
    payRecordStatusList,
    currencyList,
    payRecord,
    baseCurrency,
    payeeDropdownList,
    get_pay_record_status_list,
    get_currency_list,
    get_pay_record,
    delete_pay_record_item,
    save_pay_record,
    download_pay_record,
    get_base_currency,
    reset_pay_record,
    get_payee_dropdown_list,
    get_payments

}) => {

    const initialState = {
        payRecordRefNum: '0',   
        payee: '',
        subPayee:'',
        description: '',
        currency: '',
        recordDate: '',
        paymentDate: '',
        payRecordStatus: 'Draft',
        paidAmount: 0,
        invoiceAmountReceived: '',
        invoiceItemRefNum: '',
        totalGross: '',
        totalTax: '',
        total: '',
        payRecordItems: []
    }
    const [isSubmitting, setSubmitting] = useState(false);
    const [formData, setFormData] = useState({...initialState})
    let [redirectAddNewItem, setRedirectAddNewItem] = useState(false);

    const { refNum } = useParams();
    const backStepRef = useRef(0)
    const formDataRef = useRef({});
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    //For storing state and ids
    const location = useLocation();
    const [stateId, setStateId] = useState(0);
    const history = useHistory();

    const [confirmAlert, setConfirmAlert] = useState({
        confirmMsg: "",
        visible: false,
        onConfirm: null
    });
    const [openModal, setOpenModal] = useState(false);
    const [paymentType, setPaymentType] = useState('Outgoing');
    const getPayments = async (paymentType)=>{
        if(!openModal){
            const fetched = await get_payments(paymentType === 'Incoming' ? 'PayRecordIncoming' : paymentType, formData.payRecordRefNum);
            setOpenModal(fetched);
        }else{
            setOpenModal(!openModal);

        }
    }
    const toggle = async () => {
            setOpenModal(!openModal);
    }
    const onDeletePayRecordItem = (refNum) => {
        setConfirmAlert({
            ...confirmAlert,
            confirmMsg: 'Do you want to Delete the Payable Item?',
            visible: true,
            onConfirm: async () => {

                const deleted = await delete_pay_record_item(refNum)
                if (deleted) {
                    get_pay_record(formData.payRecordRefNum)
                }

            }
        })
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        if(isSubmitting) return;

        setSubmitting(true);
        let formDataToSubmit = { ...formData }
        formDataToSubmit.recordDate = setInputDate(formDataToSubmit.recordDate);
        formDataToSubmit.paymentDate = setInputDate(formDataToSubmit.paymentDate);
        formDataToSubmit.payRecordStatus =  formDataToSubmit.payRecordStatus === "Draft" ? "Created" : formDataToSubmit.payRecordStatus;
        let isChangedFormData = !compareObjects(formData, formDataRef.current);
        delete formDataToSubmit.payRecordItems;

        if (e.nativeEvent.submitter.name === 'addPayRecordItem') {
            if (formData.payRecordRefNum === '0' || isChangedFormData) {
                let saved = await save_pay_record(JSON.stringify(formDataToSubmit))
                if (!saved) {
                    setSubmitting(false);
                    return;
                }

            }
            setRedirectAddNewItem(true)
        } else {
            let saved = await save_pay_record(JSON.stringify(formDataToSubmit))
            if (saved){
                history.replace(`/view-payables`);
                //history.go(backStepRef.current);
            }else{
                setSubmitting(false);
            } 
        }

    }
    const payRecordStatusListOptions = (payRecordStatusList || []).map((status, i) => <option key={status + i} value={status} >{status}</option>)
    const currencyListOptions = (currencyList || []).map(currency => <option key={currency} value={currency}>{currency}</option>)
    const payeeListOptions = (payeeDropdownList || []).map(payee => ({ value: payee.refId, label: payee.payeeName }));
    const subPayeeListOptions = (payeeDropdownList || []).map(payee => ({ value: payee.refId, label: payee.payeeName }));
    const onEditPayRecordItem = (refNum) => {
        navigateToPayableAddEdit(refNum, formData.payRecordRefNum)
        //history.replace(`/add-edit-payable-item/${refNum}/${formData.payRecordRefNum}`)
    }

    const getFormData = (payRecord) => {
        return {
            payRecordRefNum: payRecord.payRecordRefNum || formData.payRecordRefNum,
            payee: payRecord.payee || formData.payee,
            subPayee: payRecord.subPayee || formData.subPayee,
            description: payRecord.description || formData.description,
            currency: payRecord.currency || formData.currency,
            recordDate: payRecord.recordDate ? setAPIDate(payRecord.recordDate) : formData.recordDate,
            paymentDate: payRecord.paymentDate ? setAPIDate(payRecord.paymentDate) : formData.paymentDate,
            payRecordStatus: payRecord.payRecordStatus || formData.payRecordStatus,
            paidAmount: payRecord.paidAmount || formData.paidAmount,
            invoiceAmountReceived: payRecord.invoiceAmountReceived || formData.invoiceAmountReceived,
            invoiceItemRefNum: payRecord.invoiceItemRefNum || formData.invoiceItemRefNum,
            totalGross: payRecord.totalGross || formData.totalGross,
            totalTax: payRecord.totalTax || formData.totalTax,
            total: payRecord.total || formData.total,
            payRecordItems: payRecord.payRecordItems || formData.payRecordItems
        }
    }
    useEffect(() => {
        setFormData({ ...formData, ...getFormData(payRecord) })
        formDataRef.current = { ...getFormData(payRecord) }
        if (payRecord.payRecordRefNum) {
            if (redirectAddNewItem) {
                navigateToPayableAddEdit(0, payRecord.payRecordRefNum)
                //history.replace(`/add-edit-payable-item/0/${payRecord.payRecordRefNum}`);
            }
        }
    }, [payRecord, redirectAddNewItem])
    useEffect(()=>{
        if(formData.payRecordRefNum === '0'){
            setFormData({...formData, currency:baseCurrency.currencyCode})
        }
    },[baseCurrency])

    useEffect(() => {
        if(stateId === 0){
            if(location.state !== undefined && location.state !== null){
                //Take from state
                setStateId(location.state?.refNum);
                get_pay_record(location.state?.refNum)
            }
            else if(localStorage.getItem(NavgationPageLocationKey.PayableCurrentAddEdit) !== null){
                //Take from local storage 
                setStateId(localStorage.getItem(NavgationPageLocationKey.PayableCurrentAddEdit));
                get_pay_record(localStorage.getItem(NavgationPageLocationKey.PayableCurrentAddEdit))
                const newState = { refNum: localStorage.getItem(NavgationPageLocationKey.PayableCurrentAddEdit) };
                history.push(location.pathname, newState);
                localStorage.removeItem(NavgationPageLocationKey.PayableCurrentAddEdit);
            }
        }
        // if (location.state?.refNum !== '0') {
        //     get_pay_record(location.state?.refNum)
        // }
        // if (stateId !== '0') {
        //     get_pay_record(stateId)
        // }
        get_pay_record_status_list();
        get_currency_list();
        get_base_currency();
        get_payee_dropdown_list();

        return () => reset_pay_record
    }, [])

    function navigateToPayableAddEdit(refNum, payRecordRefNum){ 
        history.push({
          pathname: `/add-edit-payable-item`,
          state: { refNum: refNum, payRecordRefNum: payRecordRefNum }
        });
    }

    return (<>
        <ConfirmAlert confirmAlert={confirmAlert}
            setConfirmAlert={setConfirmAlert}
        />
        <ViewIncomingAndOutGoingPayments
        openModal={openModal}
        onShowModal={()=>{}}
        toggle={toggle}
        module={"PayRecord"}
        paymentType={paymentType}
        //referenceId={location.state?.refNum}
        referenceId={stateId}
        currency={formData.currency}
        headerTitle={<span><span>{`${paymentType} Payments`}</span> 
            <span className="font-weight-normal">{` - Payable # `}
                {<span className="primary-text">{`PAY-${stateId}`}</span>}
            </span></span>}
        />
        <Container fluid className="main-content-container p-4">
            <Card small className="mb-4 w-100 ">
                <ListGroup flush>
                    <Form onSubmit={onSubmit}>
                        <ListGroupItem className="border-card p-3 w-100">
                            <Row className="mb-2 h-100">
                                <Col lg="6" className="form-group m-0 p-2 pr-5">
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="payee" className="">Payee</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <Dropdown
                                                        name='payee'
                                                        id='payee'
                                                        required={true}
                                                        options={payeeListOptions}
                                                        placeholder='Enter Payee'
                                                        selectedValues={[formData.payee]}
                                                        onChange={(name, value) =>{
                                                             setFormData({ ...formData, [name]: value.length > 0 ? value[0].value: 0 })}}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="subPayee" className="">Sub-Payee</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <Dropdown
                                                        name='subPayee'
                                                        id='subPayee'
                                                        required={true}
                                                        options={subPayeeListOptions}
                                                        placeholder='Enter Sub-Payee '
                                                        selectedValues={[formData.subPayee]}
                                                        onChange={(name, value) =>{
                                                             setFormData({ ...formData, [name]: value.length > 0 ? value[0].value: 0 })}}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="description" className="">Description</label>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <FormInput
                                                        id="description"
                                                        name="description"
                                                        value={formData.description}
                                                        onChange={onChange}
                                                        placeholder="Enter Description"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="currency" className="">Currency</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <FormSelect
                                                        id="currency"
                                                        name="currency"
                                                        value={formData.currency}
                                                        onChange={onChange}
                                                        required={true}
                                                    >
                                                        <option key='currency' value=''>Select Currency</option>
                                                        {currencyListOptions}
                                                    </FormSelect>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pt-2">
                                                    <label htmlFor="recordDate" className="">Record Date</label>
                                                    <span aria-hidden="true" className="required "> *</span>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <DatePicker
                                                        id='recordDate'
                                                        autoComplete='off'
                                                        name='recordDate'
                                                        dateFormat='yyyy-MM-dd'
                                                        value={formData.recordDate}
                                                        selected={formData.recordDate}
                                                        required
                                                        onChange={(date) =>
                                                            setFormData({ ...formData, 'recordDate': date })
                                                        }
                                                        placeholderText="YYYY-MM-DD"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="py-1">
                                        <Col lg="12" className="form-group pr-5">
                                            <Row className="ml-2">
                                                <Col lg="3" className="form-group my-auto pr-0 pt-2">
                                                    <label htmlFor="paymentDate">Payment Date</label>
                                                </Col>
                                                <Col lg="9" className="form-group my-auto">
                                                    <DatePicker
                                                        id='paymentDate'
                                                        autoComplete='off'
                                                        name='paymentDate'
                                                        dateFormat='yyyy-MM-dd'
                                                        value={formData.paymentDate}
                                                        selected={formData.paymentDate}
                                                        onChange={(date) =>
                                                            setFormData({ ...formData, 'paymentDate': date })
                                                        }
                                                        placeholderText="YYYY-MM-DD"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6" className="form-group m-0 p-2 px-3">
                                    <Row>
                                        <Col lg="6" className="form-group m-0">
                                            <Row className="d-flex flex-row justify-content-end">
                                                <Col lg="12" className="form-group m-0 pl-0 ">
                                                    <Row className="ml-2 ">
                                                        <Col lg="12" className="form-group my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <span> <label htmlFor="refNum" className="">Payable #</label></span>
                                                            <label htmlFor="refNum" className="w-50 text-left pl-4" >{Number(formData.payRecordRefNum) === 0 ? `New` : <span className="primary-text">{`PAY-${formData.payRecordRefNum}`}</span>}</label>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-2 py-2">
                                                        <Col lg="7" className="form-group my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <span> <label htmlFor="payRecordStatus" className="">Payable Status</label></span>
                                                        </Col>
                                                        <Col lg="5" className="form-group my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <FormSelect
                                                                id="payRecordStatus"
                                                                name="payRecordStatus"
                                                                value={formData.payRecordStatus}
                                                                onChange={onChange}>
                                                                <option key={`status`} value=''>Select Status</option>
                                                                {payRecordStatusListOptions}
                                                            </FormSelect>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-2 py-2">
                                                        <Col lg="7" className="form-group my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <span> <label htmlFor="paidAmount" className="">Paid Amount</label></span>
                                                        </Col>
                                                        <Col lg="5" className="form-group my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <StandardFormInputFreeWidth
                                                                id="paidAmount"
                                                                name='paidAmount'
                                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                                dataMaxFracDigits="2"
                                                                type="text"
                                                                onChangeStandardInput={() => { }}
                                                                value={formData.paidAmount}
                                                                disabled={true}
                                                                placeholder="0.00"
                                                                align='right'
                                                                labelClasses="my-auto"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-2 py-2">
                                                        <Col lg="7" className="form-group my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <span> <label htmlFor="invoiceAmountReceived" className="">Invoice Amount Received</label></span>
                                                        </Col>
                                                        <Col lg="5" className="form-group my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <StandardFormInputFreeWidth
                                                                id="invoiceAmountReceived"
                                                                name='invoiceAmountReceived'
                                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                                dataMaxFracDigits="2"
                                                                type="text"
                                                                value={formData.invoiceAmountReceived}
                                                                onChangeStandardInput={() => { }}
                                                                disabled={true}
                                                                placeholder="0.00"
                                                                align='right'
                                                                labelClasses="my-auto"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-2 py-2">
                                                        <Col lg="7" className="form-group my-auto pt-2 px-0 d-flex flex-row justify-content-between">
                                                            <Button disabled={true} onClick={()=>{}}>Recalculate Received</Button>
                                                        </Col>
                                                        <Col lg="5" className="form-group my-auto pt-2 px-0 d-flex flex-row justify-content-between">

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg="6" className="form-group m-0 p-2 px-2 mt-4 d-flex flex-column justify-content-center">
                                            <Row className="ml-2  ml-2 pt-3">
                                                <Col lg="12" className="form-group mt-1 pt-3 px-4 d-flex flex-row justify-content-end">
                                                    <Button onClick={()=>{
                                                        setPaymentType('Outgoing');
                                                        if(formData.payRecordRefNum !== '0'){
                                                            getPayments('Outgoing');
                                                        }
                                                        else {
                                                            toggle();
                                                        }
                                                    }}>View Outgoing Payments</Button>
                                                </Col>
                                            </Row>
                                            <Row className="ml-2 py-1 ml-2 py-2 ">
                                                <Col lg="12" className="form-group my-auto pt-1 px-4 d-flex flex-row justify-content-end">
                                                    <Button onClick={()=>{
                                                        setPaymentType('Incoming');
                                                        if(formData.payRecordRefNum !== '0'){
                                                            getPayments('Incoming');
                                                        }
                                                        else {
                                                            toggle();
                                                        }
                                                        }}>View Incoming Payments</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col sm lg="12 d-flex flex-row justify-content-between">
                                    <span className="ml-2 pl-1 pt-1 primary-text font-weight-bold">Payable Details</span>
                                    <Button name="addPayRecordItem">Add Payable Item</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm lg="12 m-0 p-0">
                                    <table className="table">
                                        <thead>
                                            <th className="text-center p-1" width="42%" >Description</th>
                                            <th className="text-left p-1" width="10%">Week Ending</th>
                                            <th className="text-right p-1" width="8%">Hrs/Qty</th>
                                            <th className="text-right p-1 pr-2" width="8%">Rate</th>
                                            <th className="text-right p-1 pr-2" width="8%">Gross</th>
                                            <th className="text-right p-1 pr-2" width="8%">Tax</th>
                                            <th className="text-right p-1 pr-2" width="8%">Total</th>
                                            <th className="text-center p-1" width="8%">Action</th>
                                        </thead>
                                        {(formData.payRecordItems || []).map((item, i) => <tr id={i}>
                                            <td className="p-1 pl-5">{item.description}</td>
                                            <td className="p-1 pl-2">{setDateForTable(item.weekendingDate)}</td>
                                            <td align="right" className="p-1 pr-3">{formatNumberAmount(item.hrsQty, 'en-US', 2, 2)}</td>
                                            <td align="right" className="p-1">{formatNumberAmount(item.payRate, 'en-US', 2, 2)}</td>
                                            <td align="right" className="p-1">{formatNumberAmount(item.gross, 'en-US', 2, 2)}</td>
                                            <td align="right" className="p-1">{formatNumberAmount(item.tax, 'en-US', 2, 2)}</td>
                                            <td align="right" className="p-1">{formatNumberAmount(item.total, 'en-US', 2, 2)}</td>
                                            <td align="center" className="p-1"><span><img src="./icons/edit-square.svg" role="button" height="17.5" width="17.5" className="mr-2" onClick={() => {

                                                onEditPayRecordItem(item.payRecordItemRefNum);
                                            }}></img> <img role="button" src="./icons/cross-icon.png" height="17.5" width="17.5" onClick={() => onDeletePayRecordItem(item.payRecordItemRefNum)}></img></span></td>
                                        </tr>)}

                                        {(formData.payRecordItems || []).length === 0 && <td colSpan="8" className="p-4"></td>}
                                        <tr>
                                            <td colSpan="3"></td>
                                            <td align="right" colSpan="2" className="p-1 font-weight-bold">{`${formData.currency + ' ' + formatNumberAmount(formData.totalGross, 'en-US', 2)}`}</td>
                                            <td align="right" className="p-1 font-weight-bold">{formatNumberAmount(formData.totalTax, 'en-US', 2, 2)}</td>
                                            <td align="right" className="p-1 font-weight-bold">{formatNumberAmount(formData.total, 'en-US', 2, 2)}</td>
                                            <td colSpan="1"></td>
                                        </tr>

                                        <td colSpan="8"></td>

                                    </table>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" className="d-flex flex-row justify-content-end">
                                    <Button
                                        className="mx-1 py-2 mx-md-2"
                                        size="sm"
                                        theme="accent" name="save"
                                    >Save Payable</Button>
                                    <Button
                                        className="mx-1 py-2 mx-md-2"
                                        size="sm"
                                        theme="accent" name="download"
                                        onClick={() => {
                                            if (formData.payRecordRefNum === '0') {
                                                setAlert('Payable is not available for ref number 0', 'danger')
                                                return;
                                            }

                                            download_pay_record(formData.payRecordRefNum)
                                        }}
                                    >Download Payable PDF</Button>
                                    <Button
                                        className="mx-1 py-2 mx-md-2"
                                        size="sm"
                                        theme="accent" name="cancel"
                                        onClick={() => history.replace(`/view-payables`)}
                                    >Cancel</Button>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </Form>
                </ListGroup>
            </Card>
        </Container>
    </>)
}

const mapStateToProps = (state) => (
    {
        payRecordStatusList: state.payRecord.payRecordStatusList,
        currencyList: state.invoice.currencyList,
        payRecord: state.payRecord.payRecord,
        payeeList: state.payConfiguration.payeeList,
        payRecordFile: state.payRecord.payRecordFile,
        baseCurrency: state.user.baseCurrency,
        payeeDropdownList:state.payConfiguration.payeeDropdownList
    }
)
export default connect(mapStateToProps, {
    get_pay_record_status_list,
    get_currency_list,
    get_pay_record,
    delete_pay_record_item,
    save_pay_record,
    get_payee_configuration_list,
    download_pay_record,
    get_base_currency,
    reset_pay_record,
    get_payee_dropdown_list,
    get_payments
})(PayRecord)