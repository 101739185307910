import { connect } from "react-redux"
import { Button, Card, Col, Container, DatePicker, Form, FormInput, FormSelect, ListGroup, ListGroupItem, Row } from "shards-react"
import StandardFormInputFreeWidth from "../../../components/common/StandardFormInputFreeWidth"
import { NavgationPageLocationKey, STANDARD_FORMATS } from "../../../utils/constants"
import { Link, useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useRef, useState } from "react"
import { get_item_type_list, get_worker_list_by_client } from "../../../redux/actions/subscriber/invoice"
import { get_new_payComponent_name_list, get_pay_schedule, get_pay_schedule_list, reset_pay_schedule } from "../../../redux/actions/subscriber/payroll"
import { useEffect } from "react"
import { calculate_pay_record_item_details, get_pay_record_item, reset_pay_record_item, save_payrecord_item } from "../../../redux/actions/subscriber/payRecord"
import { setAPIDate, setInputDate } from "../../../utils/date"
import { get_client_list } from "../../../redux/actions/user"
import InvoicePopUp from "./InvoicePopUp"
import { CheckValueCorrectionForMiniCalcField, fixedDecimalPlaces, IsValidProcessNumber, prevent_enter_submit } from "../../../utils/general"
import { evaluate, string } from "mathjs"
import { setAlert } from "../../../redux/actions/alert"

const PayRecordItem = ({
    itemTypeList,
    workerList,
    newPayComponentNameList,
    payScheduleList,
    paySchedule,
    clientList,
    calculatedPayRecordItemDetails,
    payRecordItem,
    get_item_type_list,
    get_worker_list_by_client,
    get_pay_schedule_list,
    get_new_payComponent_name_list,
    get_pay_schedule,
    save_payrecord_item,
    reset_pay_schedule,
    calculate_pay_record_item_details,
    get_client_list,
    get_pay_record_item,
    reset_pay_record_item,
    setAlert
}) => {

    //const { refNum, payRecordRefNum } = useParams();

    //For storing state and ids
    const location = useLocation();
    const [refNum, setrefNum] = useState(0);
    const [payRecordRefNum, setpayRecordRefNum] = useState(0);

    const history = useHistory();
    const [formData, setFormData] = useState({
        payRecordItemRefNum: refNum,
        payRecordRefNum: payRecordRefNum,
        clientId: '0',
        workerId: '0',
        invoiceRefNum: '0',
        itemTypeId: '',
        subTypeId: '0',
        description: '',
        weekendingDate: '',
        hrsQty: '',
        payRate: '',
        payRecordDate: '',
        payScheduleId: '0',
        payPeriodId: '',
        invoiceItemRate: '',
        payRecordItemRate: '',
        total: '',
        gross: '',
        tax: ''
    })

    //For Calculation and Formula
    const [rawData, setRawData] = useState({
        gross: '',
        hrsQty: '',
        payRate: '',
        total: '',
        isHrsQtyEvalDisable: true,
        isPayRateEvalDisable: true,
    })

    const [isSubmitting, setSubmitting]= useState(false);
    const isFirstRender = useRef(refNum !== '0');
    const [openModal, setOpenModal] = useState(false);
    const toggle = () => {
            setOpenModal(!openModal);
    
        }
    const setSelectedInvoiceRefNum = (refNum)=>{
            setFormData({...formData, invoiceRefNum:refNum});
        }
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onChangeStandardInput = (name, value) => setFormData({ ...formData, [name]: value });

    const onSubmit = async (e) => {
        e.preventDefault()

        if(isSubmitting) return;
        setSubmitting(true);
        
        let formDataToSubmit = { ...formData }
        formDataToSubmit.weekendingDate = setInputDate(formDataToSubmit.weekendingDate);
        formDataToSubmit.payRecordDate = setInputDate(formDataToSubmit.payRecordDate);
        
        formDataToSubmit.payRecordItemRefNum = refNum;
        formDataToSubmit.payRecordRefNum = payRecordRefNum;
        //Check formula exists for hrsQty & payRate -- Added by nipul # 2313
        if(checkArthimaticOperators('hrsQty', rawData.hrsQty) || checkArthimaticOperators('payRate', rawData.payRate)){
            try{
                //Do all calculation if any one field have any formula
                let calHours = String(fixedDecimalPlaces(evaluate(string(rawData.hrsQty)), 2));
                let calRate = String(fixedDecimalPlaces(evaluate(string(rawData.payRate)), 2));
                if(calHours === "Infinity" || calRate === "Infinity"){
                    setAlert("Cannot divide value by 0", 'danger');
                    return false;
                }
                let calCulatedGross = fixedDecimalPlaces(Number(calHours) * Number(calRate), 2);
                let calCulatedTotal = fixedDecimalPlaces(Number(calCulatedGross) + Number(formData.tax), 2);
                formDataToSubmit.hrsQty = calHours;
                formDataToSubmit.payRate = calRate;
                //Added as replica of Pay Rate for now 
                formDataToSubmit.payRecordItemRate = calRate;
                formDataToSubmit.gross = calCulatedGross;
                formDataToSubmit.total = calCulatedTotal;
            }
            catch(ex){
                setAlert("Please provide proper value to calculate", 'danger');
                setSubmitting(false);
                return false;
            }
        }
        else {
            //Check validation for values
            if(CheckValueCorrectionForMiniCalcField(rawData.payRate)){
                setAlert("Please provide proper value into Pay Rate", 'danger');
                setSubmitting(false);
                return false;
            }
            else if(CheckValueCorrectionForMiniCalcField(rawData.hrsQty)){
                setAlert("Please provide proper value into Hrs/Qty", 'danger');
                setSubmitting(false);
                return false;
            }
        }

        let saved = await save_payrecord_item(JSON.stringify(formDataToSubmit));
        if (saved){
            history.push({
                pathname: `/payable`,
                state: { refNum: payRecordRefNum }
            });
        } else{
            setSubmitting(false);
        }

    }
    const clientListOptions = (clientList || []).map(client => <option key={client.clientName + client.clientId} value={client.clientId}>{client.clientName}</option>)
    const workerListOptins = Number(formData.clientId) !== 0 && workerList.map(worker => <option key={worker.name + worker.userId} value={worker.userId}>{worker.name}</option>)
    const subTypeListOptions = newPayComponentNameList.map(newPayComponent => <option key={newPayComponent.payComponentName + newPayComponent.payComponentId} value={newPayComponent.payComponentId}>{newPayComponent.payComponentName}</option>)
    const itemTypeListOptions = itemTypeList.map(itemType => <option key={itemType.categoryLabel + itemType.payComponentRefNum} value={itemType.payComponentRefNum}>{itemType.categoryLabel}</option>)
    const payScheduleListOptions = payScheduleList.map(paySchedule => <option key={paySchedule.payScheduleName + paySchedule.payScheduleRefNum} value={paySchedule.payScheduleRefNum}>{paySchedule.payScheduleName}</option>)
    const payPeriodListOptions = paySchedule.payPeriodList && paySchedule.payPeriodList.map(payPeriod => <option key={payPeriod.payPeriodName + payPeriod.refNum} value={payPeriod.refNum}>{payPeriod.payPeriodName}</option>)

    const getFormData = (payRecordItem) => {
        return {
            //payRecordItemRefNum: payRecordItem.payRecordItemRefNum || formData.refNum,
            payRecordItemRefNum: payRecordItem.payRecordItemRefNum || formData.payRecordItemRefNum,//Updated by nipul
            payRecordRefNum: payRecordItem.payRecordRefNum || formData.payRecordRefNum,
            clientId: payRecordItem.clientId || formData.clientId,
            workerId: payRecordItem.workerId || formData.workerId,
            invoiceRefNum: payRecordItem.invoiceRefNum || formData.invoiceRefNum,
            itemTypeId: payRecordItem.itemTypeId !== undefined ? payRecordItem.itemTypeId : formData.itemTypeId,
            subTypeId: payRecordItem.subTypeId || formData.subTypeId,
            description: payRecordItem.description || formData.description,
            weekendingDate: payRecordItem.weekendingDate ? setAPIDate(payRecordItem.weekendingDate) : formData.weekendingDate,
            hrsQty: payRecordItem.hrsQty || formData.hrsQty,
            payRate: payRecordItem.payRate || formData.payRate,
            payRecordDate: payRecordItem.payRecordDate ? setAPIDate(payRecordItem.payRecordDate) : formData.payRecordDate,
            payScheduleId: payRecordItem.payScheduleId || formData.payScheduleId,
            payPeriodId: payRecordItem.payPeriodId || formData.payPeriodId,
            invoiceItemRate: payRecordItem.invoiceItemRate || formData.invoiceItemRate,
            payRecordItemRate: payRecordItem.payRecordItemRate || formData.payRecordItemRate,
            total: payRecordItem.total || formData.total,
            gross: payRecordItem.gross || formData.gross,
            tax: payRecordItem.tax || formData.tax
        }
    }
    useEffect(() => {
        setFormData({...formData, ...getFormData(payRecordItem)});
        
        //API Data
        setRawData({ ...rawData
            , total: fixedDecimalPlaces(payRecordItem['total'])
            , gross: fixedDecimalPlaces(payRecordItem['gross'])
            , payRate: fixedDecimalPlaces(payRecordItem['payRate'])
            , hrsQty: fixedDecimalPlaces(payRecordItem['hrsQty'])
        });
    }, [payRecordItem])

    const calculatePayRecordDetails = () => {
        let foundPayPeriod = (paySchedule.payPeriodList || []).filter(payPeriod => Number(payPeriod.payScheduleRefNum) === Number(formData.payScheduleId) && Number(payPeriod.refNum) === Number(formData.payPeriodId))
        let foundWorker = (workerList || []).filter(worker => Number(worker.userId) === Number(formData.workerId))

        if ((foundWorker || []).length === 0) {
            setFormData({ ...formData, workerId: '0' })
            return;
        }
        if (foundPayPeriod.length === 0) {
            setFormData({ ...formData, payPeriodId: '0' })

        }
        if (foundPayPeriod.length === 0 && (formData.payRecordDate === '' || formData.payRecordDate === null)) {
            return;
        }

        if (formData.workerId !== '0' && formData.subTypeId !== '0' && formData.itemTypeId !== '' && (formData.payRecordDate !== '' || (formData.payScheduleId !== '0' && formData.payScheduleId !== '0'))) {
            // Not needed as below change is restriction first call to API - Change by nipul
            // if (isFirstRender.current) {
            //     isFirstRender.current = false;
            //     return;
            // }
            calculate_pay_record_item_details({ itemTypeId: formData.itemTypeId, subTypeId: formData.subTypeId, workerId: formData.workerId, date: setInputDate(formData.payRecordDate), payRecordItemPayScheduleId: formData.payScheduleId, payRecordItemPayPeriodId: formData.payPeriodId })

        }
    }
    useEffect(() => {

        calculatePayRecordDetails();
    }, [formData.subTypeId, formData.itemTypeId, formData.payRecordDate, formData.payPeriodId, formData.workerId, formData.payScheduleId])

    useEffect(() => {
        if (Number(formData.payScheduleId) !== 0) {
            get_pay_schedule(formData.payScheduleId);
        }
    }, [formData.payScheduleId])

    useEffect(() => {
        if (Number(formData.clientId) !== 0) {
            get_worker_list_by_client(formData.clientId);
        }
    }, [formData.clientId])

    useEffect(() => {
        if (formData.payScheduleId === '0') {
            let defaultPaySchedule = payScheduleList.filter(paySchedule => paySchedule.defaultPaySchedule);
            if (defaultPaySchedule && defaultPaySchedule.length > 0) {
                setFormData({ ...formData, payScheduleId: defaultPaySchedule[0].payScheduleRefNum })
            }
        }
    }, [payScheduleList])

    useEffect(() => {
        setFormData({
            ...formData,
            description: calculatedPayRecordItemDetails.description,
            weekendingDate: setAPIDate(calculatedPayRecordItemDetails.weekEnding),
            hrsQty: calculatedPayRecordItemDetails.hrsQty,
            invoiceItemRate: calculatedPayRecordItemDetails.invoiceRate,
            payRate: calculatedPayRecordItemDetails.payRate,
            gross: calculatedPayRecordItemDetails.gross,
            tax: calculatedPayRecordItemDetails.tax,
            total: calculatedPayRecordItemDetails.total,
            exportLabel: calculatedPayRecordItemDetails.exportLabel,
            //Added by nipul
            payRecordItemRate: calculatedPayRecordItemDetails.payRate,
        })

        //API Data
        setRawData({ ...rawData
            , total: fixedDecimalPlaces(calculatedPayRecordItemDetails['total'])
            , gross: fixedDecimalPlaces(calculatedPayRecordItemDetails['gross'])
            , payRate: fixedDecimalPlaces(calculatedPayRecordItemDetails['payRate'])
            , hrsQty: fixedDecimalPlaces(calculatedPayRecordItemDetails['hrsQty'])
        });
    }, [calculatedPayRecordItemDetails])

    // useEffect(() => {
    //     setFormData({ ...formData, payRecordItemRate: formData.payRate })
    // }, [formData.payRate])

    useEffect(() => {
        setFormData({ ...formData, payRate: formData.payRecordItemRate })
    }, [formData.payRecordItemRate])

    useEffect(() => {
        if(refNum === 0){
            if(location.state !== undefined){
                //Take from state --> Also Add screnario will fall here
                let itemId = location.state.refNum;
                let payableId = location.state.payRecordRefNum;

                setrefNum(itemId);
                setpayRecordRefNum(payableId);
                if(Number(location.state?.refNum) !== 0){
                    get_pay_record_item(location.state?.refNum)
                }

                setFormData({ ...formData
                    , payRecordItemRefNum: itemId
                    , payRecordRefNum: payableId
                })
            }
            else if(localStorage.getItem(NavgationPageLocationKey.PayableCurrentItem_RefNum) !== null
                && localStorage.getItem(NavgationPageLocationKey.PayableCurrentItem_PayRecordRefNum) !== null){
                //Take from local storage 
                setrefNum(localStorage.getItem(NavgationPageLocationKey.PayableCurrentItem_RefNum));
                setpayRecordRefNum(localStorage.getItem(NavgationPageLocationKey.PayableCurrentItem_PayRecordRefNum));
                get_pay_record_item(localStorage.getItem(NavgationPageLocationKey.PayableCurrentItem_RefNum))
                //Set state for same page
                const newState = { refNum: localStorage.getItem(NavgationPageLocationKey.PayableCurrentItem_RefNum)
                    , payRecordRefNum: localStorage.getItem(NavgationPageLocationKey.PayableCurrentItem_PayRecordRefNum) }
                history.push(location.pathname, newState);
                localStorage.removeItem(NavgationPageLocationKey.PayableCurrentItem_RefNum);
                localStorage.removeItem(NavgationPageLocationKey.PayableCurrentItem_PayRecordRefNum);
            }
        }
        // if (refNum !== '0') {
        //     get_pay_record_item(refNum);
        // }
        get_item_type_list();
        get_pay_schedule_list();
        get_new_payComponent_name_list();
        get_client_list();
        return ()=>{
            reset_pay_schedule();
            reset_pay_record_item();
        } 
    }, [])

    // Added by nipul for parameter less flow
    function navigateToPayable(e, refId){
        e.preventDefault(); 
        history.push({
          pathname: `/payable`,
          state: { refNum: refId }
        });
        return false;
    }
    // Added by nipul for parameter less flow
    function navigateToInvoice(e, refId){
        e.preventDefault(); 
        history.push({
            pathname: `/new-invoice`,
            state: { refNum: refId }
        });
        return false;
    }

    function onContextMenuInvoiceChange(e, refId){
        if(e.type === 'contextmenu'){
            localStorage.setItem(NavgationPageLocationKey.InvoiceCurrentAddEdit, refId);
        }
    }

    //For evaluation auto calculation
    const onChangeRawData = (e) => {
        //Regex for allowing numbers and arithmetic operators 
        const regex = /^[0-9+\-*/().\b]*$/;
        let doesHaveOperator = checkArthimaticOperators(e.target.name, e.target.value);
        if (!regex.test(e.target.value)) {
            e.preventDefault();
        }
        else {
            CheckFormulaAndUpdate(e.target.name, e.target.value, doesHaveOperator);
        }
    }

    const CheckFormulaAndUpdate = (prop, val, doesHaveOperator) => {
        // Revised changes to accomodate manual values for gross
        switch(prop){
            case 'hrsQty' :
                if(val !== '' && doesHaveOperator){
                    setRawData({...rawData, isHrsQtyEvalDisable: false, [prop]: val });
                    return;
                }
                else {
                    let calCulatedGross = fixedDecimalPlaces(Number(val) * Number(formData.payRate), 2);
                    setFormData({ ...formData, gross: calCulatedGross, ['hrsQty']: val })
                    setRawData({ ...rawData, gross: calCulatedGross, [prop]: val, isHrsQtyEvalDisable: true })
                }
            break;
            case 'payRate':
                if(val !== '' && doesHaveOperator){
                    setRawData({...rawData, isPayRateEvalDisable: false, [prop]: val });
                    return;
                }
                else {
                    let calCulatedGross = fixedDecimalPlaces(Number(formData.hrsQty) * Number(val), 2);
                    setFormData({ ...formData, gross: calCulatedGross, ['payRate']: val })
                    setRawData({ ...rawData, gross: calCulatedGross, [prop]: val, isPayRateEvalDisable: true })
                }
            break;
            default:
                setRawData({ ...rawData, [prop]: val }); 
                setFormData({ ...formData, [prop]: val });
            break;
        }
    }
    
    const checkArthimaticOperators = (name, val) => {
        const arthimaticRegex = /[+\-*/%]/;
        //if no operation then change main form value directly
        if(arthimaticRegex.test(val)){
            setFormData({ ...formData, [name]: val });
            return true;
        }
        return false;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && checkArthimaticOperators(event.nativeEvent.target.name, event.nativeEvent.target.value)) {
            onChangeCalculationField(event.nativeEvent.target.name);
        }
    };

    const onChangeCalculationField = (prop) => {
        try{
            let evalValue = String(fixedDecimalPlaces(evaluate(string(rawData[prop])), 2));
            if(evalValue !== "Infinity")
                CheckFormulaAndUpdate(prop, evalValue, false);
            else
                setAlert("Cannot divide value by 0", 'danger');
        }
        catch(ex){
            setAlert("Please provide proper value to calculate", 'danger');
        }
    }   

    useEffect(() => {
        let calCulatedTotal = fixedDecimalPlaces(Number(formData.gross) + Number(formData.tax), 2);
        setFormData({ ...formData, total: calCulatedTotal })
        setRawData({ ...rawData, total: calCulatedTotal })
    },[formData.tax ])

    useEffect(() => {
        let calCulatedTotal = fixedDecimalPlaces(Number(rawData.gross) + Number(formData.tax), 2);
        setFormData({ ...formData, total: calCulatedTotal });
    }, [rawData.gross]) 

    return (
        <Container fluid className="main-content-container p-4">
            <InvoicePopUp
            toggle={toggle}
            openModal={openModal}
            selectedRefs={formData.invoiceRefNum}
            setSelectedRef={setSelectedInvoiceRefNum}
            />
            <Form onSubmit={onSubmit} onKeyDown={prevent_enter_submit}>
                <Card small className="mb-4 w-100 ">
                    <ListGroup flush>
                        <ListGroupItem className="border-card p-3">
                            <Row className="">
                                <Col lg="6" className="form-group m-0 px-5">
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="description" className="">Description</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormInput
                                                value={formData.description}
                                                id="description"
                                                name='description'
                                                onChange={onChange}
                                                placeholder='Enter Description'
                                                className=''>

                                            </FormInput>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="weekendingDate" className="">Week Ending</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <DatePicker
                                                id='weekendingDate'
                                                autoComplete='off'
                                                name='weekendingDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.weekendingDate}
                                                selected={formData.weekendingDate}
                                                onChange={(date) =>
                                                    setFormData({ ...formData, 'weekendingDate': date })
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="hrsQty" className="">Hrs/Qty</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            {/* <StandardFormInputFreeWidth
                                                id="hrsQty"
                                                name='hrsQty'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.hrsQty}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            /> */}
                                            <FormInput name='hrsQty' className="align-input-amount-right"
                                                value={rawData.hrsQty} 
                                                align='right' 
                                                onKeyDown={handleKeyDown}
                                                onChange={onChangeRawData}
                                                placeholder="0.00" />
                                            <Button className="ml-2" size="sm" disabled={rawData.isHrsQtyEvalDisable}
                                                onClick={() => onChangeCalculationField('hrsQty')}>
                                                <i class="fa-solid fa-calculator"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="payRate" className="">Pay Rate</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            {/* <StandardFormInputFreeWidth
                                                id="payRate"
                                                name='payRate'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.payRate}
                                                onChangeStandardInput={onChangeStandardInput}
                                                // className="w-50"
                                                placeholder="0.00"
                                                align='right'
                                            /> */}
                                            <FormInput name='payRate' className="align-input-amount-right"
                                                value={rawData.payRate} 
                                                align='right' 
                                                onKeyDown={handleKeyDown}
                                                onChange={onChangeRawData}
                                                placeholder="0.00" />
                                            <Button className="ml-2" size="sm" disabled={rawData.isPayRateEvalDisable}
                                                onClick={() => onChangeCalculationField('payRate')}>
                                                <i class="fa-solid fa-calculator"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="gross" className="">Gross</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            {/* <StandardFormInputFreeWidth
                                                id="gross"
                                                name='gross'
                                                // label="Total"
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.gross}
                                                onChangeStandardInput={onChangeStandardInput}
                                                // className="w-50"
                                                placeholder="0.00"
                                                align='right'
                                            /> */}
                                            <FormInput name='gross' className="align-input-amount-right"
                                                value={rawData.gross} 
                                                align='right' 
                                                onChange={onChangeRawData}
                                                placeholder="0.00" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="tax" className="">Tax</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            <StandardFormInputFreeWidth
                                                id="tax"
                                                name='tax'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.tax}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="total" className="">Total</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto d-flex flex-row justify-content-between">
                                            <StandardFormInputFreeWidth
                                                id="total"
                                                name='total'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.total}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6" className="form-group m-0 px-5">
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="itemTypeId" className="">Item Type</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.itemTypeId}
                                                id="itemTypeId"
                                                name='itemTypeId'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Item Type
                                                </option>
                                                {itemTypeListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="subTypeId" className="">Sub Type</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.subTypeId}
                                                id="subTypeId"
                                                name='subTypeId'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Sub Type
                                                </option>
                                                {subTypeListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="clientId" className="">Client</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.clientId}
                                                id="clientId"
                                                name='clientId'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Client
                                                </option>
                                                {clientListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="workerId" className="">Worker</label>
                                        </Col>
                                        <Col lg="9" className="form-group my-auto">
                                            <FormSelect
                                                value={formData.workerId}
                                                id="workerId"
                                                name='workerId'
                                                onChange={onChange}
                                                className='' >
                                                <option value=''>Select Worker
                                                </option>
                                                {workerListOptins}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="payScheduleId" className="">Pay Schedule</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pr-0">
                                            <FormSelect
                                                value={formData.payScheduleId}
                                                id="payScheduleId"
                                                name='payScheduleId'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Pay Schedule
                                                </option>
                                                {payScheduleListOptions}
                                            </FormSelect>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto d-flex flex-row justify-content-center pt-2 pr-0 px-0">
                                            <label htmlFor="payPeriodId" className="p-0">Pay Period</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pl-0">
                                            <FormSelect
                                                value={formData.payPeriodId}
                                                id="payPeriodId"
                                                name='payPeriodId'
                                                onChange={onChange}
                                                className=''>
                                                <option value=''>Select Pay Period
                                                </option>
                                                {payPeriodListOptions}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="payRecordDate" className="">Date</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pr-0">
                                            <DatePicker
                                                id='payRecordDate'
                                                autoComplete='off'
                                                name='payRecordDate'
                                                dateFormat='yyyy-MM-dd'
                                                value={formData.payRecordDate}
                                                selected={formData.payRecordDate}
                                                onChange={(date) =>
                                                    setFormData({ ...formData, 'payRecordDate': date })
                                                }
                                                placeholderText="YYYY-MM-DD"
                                            />
                                        </Col>
                                        <Col lg="6" className="form-group my-auto d-flex flex-row justify-content-center pt-2 pr-0 px-0">
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-2">
                                            <label htmlFor="invoiceItemRate" className="">Invoice Rate</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pr-0 d-flex">
                                            <StandardFormInputFreeWidth
                                                id="invoiceItemRate"
                                                name='invoiceItemRate'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.invoiceItemRate}
                                                onChangeStandardInput={onChangeStandardInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                        <Col lg="3" className="form-group my-auto d-flex flex-row justify-content-center pt-2 pr-0 px-0">
                                            <label htmlFor="payRecordItemRate" className="p-0">Pay Rate</label>
                                        </Col>
                                        <Col lg="3" className="form-group my-auto pl-0 d-flex">
                                            <StandardFormInputFreeWidth
                                                id="payRecordItemRate"
                                                name='payRecordItemRate'
                                                standardFormat={STANDARD_FORMATS.COMMA_SEPERATED_NUMBER}
                                                dataMaxFracDigits="2"
                                                type="text"
                                                value={formData.payRecordItemRate}
                                                onChangeStandardInput={onChangeStandardInput}
                                                //onChangeStandardInput={onChangeMapInput}
                                                placeholder="0.00"
                                                align='right'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-4 pt-2">
                                        <Col lg="12" className="form-group my-auto pt-2 "></Col>
                                    </Row>


                                    <Row className="mb-4">
                                        <Col lg="3" className="form-group my-auto pt-3 pr-0">
                                            <label htmlFor="clientId" className="">Invoice Reference</label>

                                        </Col>
                                        <Col lg="6" className="form-group my-auto pl-0 d-flex flex-row justify-content-center">
                                            <span className="pt-2">
                                                { Number(formData.invoiceRefNum) === 0 ?
                                                    <label className="primary-text"></label>
                                                    : <a href="/new-invoice" onClick={(e) => {navigateToInvoice(e, formData.invoiceRefNum)}}
                                                       onContextMenu={(e) => onContextMenuInvoiceChange(e, formData.invoiceRefNum)}>
                                                        {`INV-${formData.invoiceRefNum}`}
                                                    </a>
                                                    //: <Link to={`/new-invoice/${formData.invoiceRefNum}`}>{`INV-${formData.invoiceRefNum}`}</Link>
                                                }
                                            </span>
                                        </Col>

                                        {/* <Col lg="3" className="form-group my-auto  pt-2 pr-0 px-0 d-flex flex-row justify-content-end">
                                            <Button disabled={true} className=" py-2 mx-md-1" onClick={() => { }}>Create Invoice</Button>
                                        </Col> */}
                                        <Col lg="3" className="form-group my-auto pl-0 dr pt-2 pr-2 px-0 d-flex flex-row justify-content-end">
                                            <Button  className=" py-2 mx-md-2" onClick={toggle}>Find Invoice</Button>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row className="mb-4 px-4">
                                <Col lg="12" className="form-group my-auto pt-2 d-flex justify-content-end">
                                    <Button className=" py-2 mx-md-2">Save</Button>
                                    <Button className=" py-2 mx-md-2" onClick={(e) => {navigateToPayable(e, payRecordRefNum)}}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Form>
        </Container>
    )
}
const mapStateToProps = (state) => ({
    itemTypeList: state.invoice.itemTypeList,
    subTypeList: state.invoice.subTypeList,
    workerList: state.invoice.workerList,
    newPayComponentNameList: state.payroll.newPayComponentNameList,
    payScheduleList: state.payroll.payScheduleList,
    paySchedule: state.payroll.paySchedule,
    clientList: state.user.clientList,
    calculatedPayRecordItemDetails: state.payRecord.calculatedPayRecordItemDetails,
    payRecordItem: state.payRecord.payRecordItem
})
export default connect(mapStateToProps, {
    get_item_type_list,
    get_worker_list_by_client,
    get_pay_schedule_list,
    get_new_payComponent_name_list,
    get_pay_schedule,
    save_payrecord_item,
    reset_pay_schedule,
    calculate_pay_record_item_details,
    get_client_list,
    get_pay_record_item,
    reset_pay_record_item,
    setAlert
})(PayRecordItem)